import React from 'react'
import PropTypes from 'prop-types'
import { Button, Paper, Typography } from '@mui/material'
import withStyles from '@mui/styles/withStyles'
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos'
import { HOME } from '../_constants/routes'
import { compose } from 'recompose'
import ImageLost from '../images/lost.jpg'
import { useNavigate } from 'react-router'


const styles = theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
    width: '100vw',
    position: 'relative',
    background: `url(${ImageLost}) no-repeat center center fixed`,
    backgroundSize: 'cover',
  },
  paper: {
    display: 'flex',
    flexDirection: 'column',
    width: '75%',
    maxWidth: 364,
    position: 'relative',
    padding: theme.spacing(4),
    background: theme.palette.background.default,
    color: 'rgb(14, 30, 37)',
    borderRadius: 8,
  },
  button: {
    marginTop: '10px',
  },
})

const NotFoundPage = ({ classes }) => {
  
  const navigate = useNavigate()
  
  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <Typography variant='h5' gutterBottom align='center'>
          Erreur 404: Not found
        </Typography>
        <Typography variant='body1' gutterBottom align='center'>
          Are you lost ?
        </Typography>
        <Button className={classes.button} variant='contained' color='primary' fullWidth onClick={() => navigate(HOME)}>
          <ArrowBackIosIcon/>
          Back to app
        </Button>
      </Paper>
    </div>
  )
}

NotFoundPage.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default compose(
  withStyles(styles),
)(NotFoundPage)
