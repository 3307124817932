import React, { useEffect, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { Button, DialogActions, DialogContent, DialogTitle, Dialog } from '@mui/material'
import { useSelector } from 'react-redux'
import { addHours, format, startOfDay } from 'date-fns'
import UserSelect from '../_library/UserAutocomplete'
import { DatePicker, TimePicker } from '@mui/x-date-pickers'
import { AVAILABILITY_COLLECTION, ROLE_INSTRUCTOR, USER_COLLECTION } from '../../_constants/globals'
import { useFirestore } from '../../hooks/useFirestore'
import useAuth from '../../hooks/useAuth'


const WriteAvailabilityDialog = ({ open, dayInit, onClose, availability }) => {
  
  const profile = useAuth().getProfile()
  const userHooks = useFirestore(USER_COLLECTION)
  const dbUsers = userHooks.getDocs()
  const adminMode = useSelector(state => state.data.adminMode)
  const availabilityHooks = useFirestore(AVAILABILITY_COLLECTION)
  
  const [instructor, setInstructor] = useState(null)
  const [day, setDay] = useState(dayInit)
  const [start, setStart] = useState(new Date(format(dayInit, 'yyyy-MM-dd') + 'T09:00'))
  const [end, setEnd] = useState(new Date(format(dayInit, 'yyyy-MM-dd') + 'T18:00'))
  const [submitted, setSubmitted] = useState(false)
  
  const instructors = useMemo(dbUsers?.filter(u => u.roles?.includes(ROLE_INSTRUCTOR)), [dbUsers])
  
  useEffect(() => {
    instructors && profile.roles?.includes(ROLE_INSTRUCTOR) && setInstructor(profile)
  }, [instructors, profile.roles])
  
  useEffect(() => {
    setStart(prev => new Date(day.getFullYear(), day.getMonth(), day.getDate(), prev.getHours(), prev.getMinutes()))
    setEnd(prev => new Date(day.getFullYear(), day.getMonth(), day.getDate(), prev.getHours(), prev.getMinutes()))
  }, [day])
  
  useEffect(() => {
    if (availability) {
      setInstructor(instructors.find(i => i.id === availability.userRef.id))
      setDay(availability.day)
      setStart(availability.start)
      setEnd(availability.end)
    }
  }, [availability])
  
  const handleSubmit = () => {
    setSubmitted(true)
    if (availability)
      availabilityHooks.updateDoc(availability.id, {
        userRef: userHooks.getDocRef(instructor.id),
        day: startOfDay(day),
        start,
        end,
      }).then(onClose).finally(() => setSubmitted(false))
    else
      availabilityHooks.addDoc({
        userRef: userHooks.getDocRef(instructor.id),
        day: startOfDay(day),
        start,
        end,
      }).then(onClose).finally(() => setSubmitted(false))
  }
  
  return <Dialog open={open} maxWidth='xs'>
    <DialogTitle>{availability ? 'Edit dispo' : 'Nouvelle dispo'}</DialogTitle>
    <DialogContent>
      {instructors && <UserSelect
        users={instructors}
        user={instructor}
        label='Instructeur'
        set={setInstructor}
        disabled={!adminMode}
        sx={{ width: '100%', mb: 1 }}
      />}
      <DatePicker
        id='day'
        variant='inline'
        inputFormat='dd-MM-yyyy'
        value={day}
        fullWidth
        onChange={setDay}
        sx={{ mb: 1 }}
      />
      <TimePicker
        id='start'
        variant='inline'
        ampm={false}
        margin='dense'
        value={start}
        onChange={setStart}
        minutesStep={15}
        minTime={addHours(startOfDay(day), 8)}
        maxTime={addHours(startOfDay(day), 19)}
        sx={{ mb: 1 }}
      />
      <TimePicker
        id='end'
        variant='inline'
        ampm={false}
        margin='dense'
        value={end}
        onChange={setEnd}
        minutesStep={15}
        minTime={addHours(startOfDay(day), 9)}
        maxTime={addHours(startOfDay(day), 20)}
        sx={{ mb: 1 }}
      />
    </DialogContent>
    <DialogActions>
      <Button onClick={onClose}>Annuler</Button>
      {availability && <Button color='error' onClick={() => availabilityHooks.deleteDoc(availability.id)}>Supprimer</Button>}
      <Button disabled={submitted} onClick={handleSubmit}>Valider</Button>
    </DialogActions>
  </Dialog>
}

WriteAvailabilityDialog.propTypes = {
  open: PropTypes.bool,
  dayInit: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
  availability: PropTypes.object,
}

export default WriteAvailabilityDialog
