import React, { useMemo, useState } from 'react'
import { Typography, Card, Button, CardActions, CardContent, CardHeader, Box, Skeleton } from '@mui/material'
import useAuth from '../../hooks/useAuth'
import { format, formatDistanceToNow, addMonths, startOfDay } from 'date-fns'
import NewFlightDialog from '../flights/NewFlightDialog'
import { FlightTakeoff, History } from '@mui/icons-material'
import { Chart } from 'react-charts'
import { chain } from 'lodash'
import { fr } from 'date-fns/locale'
import { useFirestore } from '../../hooks/useFirestore'
import { FLIGHT_COLLECTION } from '../../_constants/globals'


export const FlightCard = () => {
  
  const flightHooks = useFirestore(FLIGHT_COLLECTION)
  const dbFlights = flightHooks.getDocs()
  const profile = useAuth().getProfile()

  const [openNewFlightDialog, setOpenNewFlightDialog] = useState(false)
  const [selectedFlightId, selectFlightId] = useState(null)
  
  const flights = useMemo(() => dbFlights?.filter(f =>
    f.startDate > addMonths(startOfDay(Date.now()), -3)
    && f.pilotRef.id === profile.id,
  ), [dbFlights])
  
  const lastFlight = useMemo(() => flights && flights[0], [flights])

  const dataFlights = useMemo(
    () => chain(flights)
      .groupBy(f => format(f.startDate, 'MMM'))
      .mapValues(arr => arr.length)
      .toPairs()
      .reverse()
      .value()
    , [flights])

  if (!flights) return <Skeleton height={460} variant='rectangular' sx={{ borderRadius: 1 }} />
  return (
    <Card sx={{ minWidth: 275, mb: 2, p: 1, position: 'relative', height: 460 }}>
      <CardHeader avatar={<FlightTakeoff />} title='Vols' sx={{ color: 'grey.600' }} />
      <CardContent>
        {lastFlight && <Box onClick={() => selectFlightId(lastFlight.id)} sx={{ bgcolor: 'grey.100', p: 1, borderRadius: 5 }}>
          <Typography variant='body1'><strong><History style={{ verticalAlign: 'bottom' }} />&nbsp;Dernier vol</strong></Typography>
          <Typography variant='body1'>
            {formatDistanceToNow(lastFlight.startDate, { addSuffix: true, locale: fr }) + ' (' + format(lastFlight.startDate, 'dd-MM-yyyy') + ')'}
          </Typography>
          {lastFlight.departure && <Typography variant='body1'>{`à ${lastFlight.departure.name}`}</Typography>}
          {lastFlight.instructor && <Typography variant='body1'>{`avec ${lastFlight.instructor.firstname}`}</Typography>}
          {lastFlight.flightType && <Typography variant='body1'>{`en ${lastFlight.flightType.name}`}</Typography>}
          {lastFlight.aircraft && <Typography variant='body1'>{`sur ${lastFlight.aircraft.name}`}</Typography>}
        </Box>
        }
        <Box sx={{ height: 150, mt: 1 }}>
          <Typography variant='body1'><strong>{`Entrainement récent (${flights.length} vols)`}</strong></Typography>
          <Chart
            data={[
              {
                label: 'Vols',
                data: dataFlights,
              },
            ]}
            axes={[
              { primary: true, type: 'ordinal', position: 'bottom' },
              { type: 'linear', position: 'left', min: 0 },
            ]}
            tooltip
            getSeriesStyle={() => ({ color: 'red' })}
          />
        </Box>
      </CardContent>
      <CardActions sx={{ position: 'absolute', bottom: theme => theme.spacing(.5), width: '100%', bgcolor: 'white' }}>
        <Button color='primary' onClick={() => setOpenNewFlightDialog(true)}>
          Saisir un vol
        </Button>
      </CardActions>
      {(openNewFlightDialog || !!selectedFlightId) && <NewFlightDialog
        open
        onClose={() => {setOpenNewFlightDialog(false); selectFlightId(null)}}
        defaultFlightId={selectedFlightId}
      />}
    </Card>
  )
}

export default FlightCard
