import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { compose } from 'recompose'
import { Card, CardContent, Grid, Typography, Button, CardActions, Collapse } from '@mui/material'
import withStyles from '@mui/styles/withStyles'
import clsx from 'clsx'
import DistributionGraph from './DistributionGraph'
import CompletenessGraph from './CompletenessGraph'
import { ArrowDropDown, ArrowDropUp } from '@mui/icons-material'
import InstructorsGraph from './InstructorsGraph'


const styles = theme => ({
  root: {
    minWidth: 275,
    maxWidth: 'calc(100vw - 270px)',
    marginBottom: theme.spacing(4),
    padding: 10,
    position: 'relative',
  },
  graph: {
    marginBottom: theme.spacing(2),
  },
  actions: {
    padding: 0,
    margin: '0 -10px -10px',
  },
  expandBtn: {
    width: '100%',
    backgroundColor: theme.palette.grey[100],
    borderRadius: 0,
    height: 20,
  },
})

export const StatCard = ({ classes, className, progressBook }) => {

  const [expand, setExpand] = useState(false)

  return (
    <Card className={clsx(className, classes.root)}>
      <CardContent>
        <Grid container>
          <Grid item xs={12}>
            <Typography variant='h5'>Items validés</Typography>
            <CompletenessGraph className={classes.graph} completeness={progressBook.completeness} />
          </Grid>
          <Grid item xs={12}>
            <Typography variant='h5'>Répartition des vols et des briefings</Typography>
            <DistributionGraph className={classes.graph} progressBook={progressBook} />
          </Grid>
          <Collapse in={expand} style={{ width: '100%' }}>
            <Grid item sm={6}>
              <Typography variant='h6'>Instructeurs ayant participé à la formation</Typography>
              <InstructorsGraph progressBook={progressBook} />
            </Grid>
          </Collapse>
        </Grid>
      </CardContent>
      <CardActions className={classes.actions}>
        <Button color='primary' onClick={() => setExpand(prev => !prev)} className={classes.expandBtn} id='expandStatCardBtn'>
          {expand
            ? <ArrowDropUp />
            : <ArrowDropDown />
          }
        </Button>
      </CardActions>
    </Card>
  )
}

StatCard.propTypes = {
  classes: PropTypes.object.isRequired,
  className: PropTypes.string,
  progressBook: PropTypes.object.isRequired,
}

export default compose(
  withStyles(styles),
)(StatCard)
