/* eslint-disable react/display-name */
import React, { useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { Avatar, Box, Card, CardContent, Tooltip } from '@mui/material'
import useAuth from '../../hooks/useAuth'
import { format } from 'date-fns'
import Legend from './Legend'
import { getImpressionSymbol } from '../../_helpers/dataHelper'
import Grader from './Grader'
import Loading from '../_library/Loading'
import { ScrollSyncPane } from 'react-scroll-sync'


const generalImpressions = [
  'Pilotage',
  'Trajectoires, Orientations',
  'Connaissances',
  'Conscience de la situation',
  'Gestion des ressources',
  'Prise de décision',
]
const getColor = impression => {
  if (impression === 'efficient') return 'success.main'
  else if (impression === 'satisfactory') return 'info.main'
  else return 'error.main'
}
const HEADCOL_WIDTH = '280px'
const headcolStyle = {
  position: 'absolute',
  width: HEADCOL_WIDTH,
  left: theme => theme.spacing(2),
  top: 'auto',
  mt: '-1px',
  whiteSpace: 'nowrap',
  overflowX: 'hidden',
  '&:hover': {
    overflowX: 'visible',
  },
}

const GeneralImpressionCard = ({ classname, lessons, editingLesson, setEditingLesson }) => {
  
  const profile = useAuth().getProfile()
  
  const [lessonHoveredId, setLessonHoveredId] = useState(null)
  
  const lessonsWithNew = useMemo(() => editingLesson.id === 'new' && editingLesson.withPractice ? [...lessons, editingLesson] : lessons, [lessons, editingLesson])
  
  return (
    <Card className={classname} id='generalImpressionCard' sx={{ mb: 4, position: 'relative' }}>
      <CardContent sx={{ pt: '60px', pr: '50px' }}>
        <Legend generalImpression />
        <ScrollSyncPane group='vertical'>
          <Box sx={{
            p: 0,
            pr: '30px',
            overflowX: 'auto',
            ml: HEADCOL_WIDTH,
          }}>
            {lessonsWithNew
              ? <Box component='table' sx={{
                borderCollapse: 'collapse',
                margin: 0,
                '& tbody td': {
                  border: 'solid 1px',
                  borderColor: 'grey.300',
                  whiteSpace: 'nowrap',
                  '&.selected': {
                    border: 'solid black 2px',
                  },
                },
                '& tbody tr:first-of-type td.clickable': {
                  borderTop: 'solid primary.main 2px',
                },
                '& tbody tr:last-child td.clickable': {
                  borderBottom: 'solid primary.main 2px',
                },
              }}>
                <thead>
                  <Box component='tr' sx={{ height: '80px' }}>
                    <Box component='th' sx={headcolStyle}>Impression générale</Box>
                    {lessonsWithNew?.map(lesson =>
                      <Box component='th' key={lesson.id} sx={{ maxWidth: '20px' }}><Box sx={{
                        width: 'fit-content',
                        transform: 'translateY(0px) translateX(0px) rotate(-45deg)',
                        whiteSpace: 'nowrap',
                      }}>
                        {editingLesson?.id !== lesson.id && format(typeof lesson.date.toDate === 'function' ? lesson.date : lesson.date, 'dd-MM-yyyy')}
                      </Box></Box>,
                    )}
                  </Box>
                </thead>
                <tbody>
                  {generalImpressions.map(title =>
                    <tr key={title}>
                      <Box component='td' sx={headcolStyle}>{title}</Box>
                      {lessonsWithNew?.map(lesson =>
                        editingLesson?.id === lesson.id  && editingLesson.generalImpression
                          ? <td key={lesson.id}>
                            <Grader
                              grade={editingLesson.generalImpression[title]}
                              setGrade={val => setEditingLesson({
                                ...editingLesson,
                                generalImpression: {
                                  ...editingLesson.generalImpression,
                                  [title]: editingLesson.generalImpression[title] === val ? null : val,
                                },
                              })}
                              generalImpression
                            />
                          </td>
                          : <Tooltip key={lesson.id} title={(lesson.generalImpression && lesson.generalImpression[title]) || ''} disableHoverListener={!lesson.generalImpression || !lesson.generalImpression[title]}>
                            <Box
                              component='td'
                              onMouseEnter={() => lesson.instructorRef?.id === profile.id && setLessonHoveredId(lesson.id)}
                              onMouseLeave={() => setLessonHoveredId(null)}
                              onClick={() => lesson.instructorRef?.id === profile.id && setEditingLesson(lesson)}
                              sx={{
                                textAlign: 'center',
                                cursor: lessonHoveredId ? 'pointer' : 'default',
                                position: 'relative',
                                bgcolor: !lesson.draft && lesson.generalImpression && Object.keys(lesson.generalImpression).length === 0 && 'grey.100',
                                borderLeft: lessonHoveredId && 'solid 2px',
                                borderRight: lessonHoveredId && 'solid 2px',
                                borderColor: lessonHoveredId && 'primary.main',
                                color: lesson.generalImpression && getColor(lesson.generalImpression[title]),
                              }}
                            >
                              {lesson.generalImpression && getImpressionSymbol(lesson.generalImpression[title])}
                            </Box>
                          </Tooltip>,
                      )}
                    </tr>,
                  )}
                </tbody>
                <tfoot>
                  <tr>
                    <td  style={{ height: 33 }} />
                    {lessonsWithNew?.map(training => training.instructor &&
                      <Tooltip title={training.instructor?.firstname + ' ' + training.instructor?.lastname} key={training.id}><td>
                        <Avatar alt='instructor avatar' src={training.instructor?.photoUrl} sx={{ width: '30px', height: '30px' }} />
                      </td></Tooltip>,
                    )}
                  </tr>
                </tfoot>
              </Box>
              : <Loading />
            }
          </Box>
        </ScrollSyncPane>
      </CardContent>
    </Card>
  )
}

GeneralImpressionCard.propTypes = {
  classname: PropTypes.string,
  lessons: PropTypes.array,
  editingLesson: PropTypes.object,
  setEditingLesson: PropTypes.func.isRequired,
}

export default GeneralImpressionCard
