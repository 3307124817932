import React, { Fragment, useEffect, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { Button, CardActions, Card, CardContent, Divider } from '@mui/material'
import { QUALIFICATION_COLLECTION, USER_COLLECTION, QUALIFICATION_TYPE_COLLECTION, STATUS_QUALIFICATIONS_SUBMITTED } from '../../_constants/globals'
import Loading from '../_library/Loading'
import { useFirestore } from '../../hooks/useFirestore'
import useAuth from '../../hooks/useAuth'
import QualificationFields from './QualificationFields'
import { omit, pickBy } from 'lodash'
import { serverTimestamp } from 'firebase/firestore'


const QualificationForm = ({ next, back }) => {
  
  const profile = useAuth().getProfile()
  const qualificationHooks = useFirestore(QUALIFICATION_COLLECTION)
  const dbQualifications = qualificationHooks.getDocs()
  const userHooks = useFirestore(USER_COLLECTION)
  const qualificationTypeHooks = useFirestore(QUALIFICATION_TYPE_COLLECTION)
  const dbQualificationTypes = qualificationTypeHooks.getDocs()
  
  useEffect(() => {
    qualificationHooks.listen({
      where: [['userRef', '==', userHooks.getDocRef(profile.id)]],
      orderBy: [['_createdAtTime', 'desc']],
    })
    return qualificationHooks.unsubscribe
  }, [profile.id])
  
  const [hydratedQualifications, setHydratedQualifications] = useState([])
  
  const qualificationTypes = useMemo(() => {
    if (dbQualificationTypes) {
      const types = dbQualificationTypes.filter(type => ['ffa', 'licence', 'sep', 'medical', 'tw', 'vp'].includes(type.slug))
      if (profile.aerobaticsLevel && profile.aerobaticsLevel !== 'Stagiaire')
        types.push(dbQualificationTypes.find(type => type.shortName === profile.aerobaticsLevel))
      return types
    }
  }, [dbQualificationTypes])
  
  useEffect(() => {
    setHydratedQualifications(qualificationTypes?.map(() => null))
  }, [qualificationTypes])
  
  const handleSubmit = () => {
    const batch = qualificationHooks.getBatch()
    hydratedQualifications
      .filter(q => Object.values(omit(q, ['typeRef'])).length > 0)
      .map(q => batch.set(qualificationHooks.getDocRef(q.id), pickBy(omit({
        ...q,
        userRef: userHooks.getDocRef(profile.id),
        _createdAtTime: !q?.id && serverTimestamp(),
        _createdByRef: !q?.id && userHooks.getDocRef(profile.id),
        _updatedAtTime: q?.id && serverTimestamp(),
        _updatedByRef: q?.id && userHooks.getDocRef(profile.id),
      }, ['id'])), { merge: true }))
    batch.update(userHooks.getDocRef(profile.id), { status: STATUS_QUALIFICATIONS_SUBMITTED })
    return batch.commit().then(next)
  }
  
  if (!dbQualifications || !qualificationTypes) return <Loading />
  return (
    <Card variant='outlined' sx={{ my: 2, mx: 'auto', maxWidth: 900 }}>
      <CardContent>
        {qualificationTypes.map((type, index) => <Fragment key={type.slug}>
          <QualificationFields
            type={type}
            onChange={data => setHydratedQualifications(prev => prev.map((v, i) => i === index ? ({ ...v, ...data }) : v))}
          />
          <Divider variant='fullWidth' light sx={{ my: 2, mx: -2 }} />
        </Fragment>)}
      </CardContent>
      <CardActions sx={{ float: 'right' }}>
        <Button size='large' onClick={back}>Précédent</Button>
        <Button size='large' onClick={handleSubmit}>Valider</Button>
      </CardActions>
    </Card>
  )
}

QualificationForm.propTypes = {
  next: PropTypes.func.isRequired,
  back: PropTypes.func.isRequired,
}

export default QualificationForm
