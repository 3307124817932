import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Typography, Button, Box } from '@mui/material'
import { InsertDriveFile, Delete } from '@mui/icons-material'
import { useStorage } from '../../hooks/useStorage'


const FileUpload = ({ fileUrl, folder, placeholder, onComplete, error, helperText, sx }) => {
  
  const storage = useStorage(folder)
  
  const [loading, setLoading] = useState(false)
  
  const submit = event => {
    setLoading(true)
    storage.uploadFileAction(event.target.files[0])
      .then(onComplete)
      .finally(() => setLoading(false))
  }
  
  const onDelete = () => {
    console.info('Deleting ', fileUrl)
    storage.deleteFile(fileUrl).then(() => onComplete(null))
  }
  
  return (
    <Box sx={{ width: 'fit-content', ...sx }}>
      <Box sx={{
        padding: '5px 8px',
        border: !loading && 'solid 1px',
        borderColor: error ? 'error.main' : 'black',
        borderRadius: 20,
      }}>
        <Box
          component='label'
          htmlFor={folder}
          sx={{
            cursor: 'pointer',
            position: 'relative',
            minWidth: '40px',
          }}
        >
          <InsertDriveFile sx={{
            verticalAlign: 'text-bottom',
            color: fileUrl ? 'success.main' : 'inherit',
          }} />
          {fileUrl && <Box component='span' role='img' sx={{
            position: 'absolute',
            bottom: 0,
            left: 0,
            fontSize: '10px',
          }}>✔️</Box>}
          {placeholder && !loading && !fileUrl && <Typography variant='body1' sx={{
            display: 'inline-block',
            fontSize: '.9rem',
            fontStyle: 'italic',
          }}>{placeholder}</Typography>}
        </Box>
        <Box
          component='input'
          type='file'
          name='file'
          id={folder}
          onChange={submit}
          sx={{
            width: '0.1px',
            height: '0.1px',
            opacity: 0,
            overflow: 'hidden',
            position: 'absolute',
            zIndex: '-1',
          }}
        />
        {fileUrl && <a href={fileUrl} target='_blank' rel='noopener noreferrer'>{folder}</a>}
        {fileUrl && <Button disableRipple onClick={onDelete} sx={{
          color: 'grey',
          minWidth: 'initial',
          verticalAlign: 'sub',
          padding: 0,
          '& .MuiSvgIcon-root': {
            fontSize: '1.2rem',
          },
        }}><Delete /></Button>}
      </Box>
      {helperText && <Typography variant='caption' sx={{ color: error && 'error.main' }}>{helperText}</Typography>}
    </Box>
  )
}

FileUpload.propTypes = {
  fileUrl: PropTypes.string,
  folder: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  onComplete: PropTypes.func.isRequired,
  error: PropTypes.bool,
  helperText: PropTypes.string,
  sx: PropTypes.object,
}

export default FileUpload
