import React, { useEffect, useMemo } from 'react'
import TabbedPage from '../_library/TabbedPage'
import { PB_ITEM_COLLECTION, PB_TEMPLATE_COLLECTION, PROGRESS_BOOK_COLLECTION } from '../../_constants/globals'
import { compact } from 'lodash'
import ProgressBookDetails from './ProgressBookDetails'
import ProgressBooks from './ProgressBooks'
import { useFirestore } from '../../hooks/useFirestore'


const ProgressBookPage = () => {
  
  const bookHooks = useFirestore(PROGRESS_BOOK_COLLECTION)
  const itemHooks = useFirestore(PB_ITEM_COLLECTION, { storeAs: 'items' })
  const templateHooks = useFirestore(PB_TEMPLATE_COLLECTION, { storeAs: 'templates' })
  
  useEffect(() => {
    bookHooks.listen({ orderBy: [['_updatedAtTime', 'desc']] })
    itemHooks.listen({ orderBy: [['order', 'asc']] })
    templateHooks.listen({ orderBy: [['order', 'asc']] })
    return () => {
      bookHooks.unsubscribe()
      itemHooks.unsubscribe()
      templateHooks.unsubscribe()
    }
  })
  
  const tabs = useMemo(() => compact([
    {
      key: 'all',
      label: 'Tous les livrets',
      route: 'all',
      content: <ProgressBooks />,
    },
    {
      key: 'view',
      label: '',
      route: '/:id',
      content: <ProgressBookDetails />,
    },
  ]), [])

  return <TabbedPage tabs={tabs} />
}

export default ProgressBookPage
