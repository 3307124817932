import {
  RESET, SELECT_AIRCRAFT, SELECT_YEAR, TOGGLE_MENU_DRAWER, SELECT_USER, SELECT_LESSON, SELECT_ACCOUNT, TOGGLE_ADMIN_MODE, SELECT_ACTIVITY, NOTIFICATIONS_ALLOWED,
} from '../types'
import { saveReduxState } from '../../_helpers/localStorageHelper'
import { getYear } from 'date-fns'


export const INITIAL_STATE = {
  selectedYear: getYear(Date.now()),
}
export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    
    case RESET:
      console.log('reset state')
      return INITIAL_STATE
    
    case SELECT_AIRCRAFT:
      saveReduxState('data', 'selectedAircraftId', action.payload)
      console.log('selectedAircraftId', action.payload)
      return { ...state, selectedAircraftId: action.payload }

    case SELECT_YEAR:
      saveReduxState('data', 'selectedYear', action.payload)
      console.log('selectedYear', action.payload)
      return { ...state, selectedYear: action.payload }

    case SELECT_USER:
      saveReduxState('data', 'selectedUserId', action.payload)
      console.log('selectedUserId', action.payload)
      return { ...state, selectedUserId: action.payload }

    case SELECT_ACCOUNT:
      saveReduxState('data', 'selectedAccountId', action.payload)
      console.log('selectedAccountId', action.payload)
      return { ...state, selectedAccountId: action.payload }

    case SELECT_LESSON:
      console.log('selectedLessonId', action.payload)
      return { ...state, selectedLessonId: action.payload }
  
    case SELECT_ACTIVITY:
      console.log('selectedActivityId', action.payload)
      return { ...state, selectedActivityId: action.payload }

    case TOGGLE_MENU_DRAWER:
      saveReduxState('data', 'menuDrawerOpen', action.payload)
      console.log('menuDrawerOpen', action.payload)
      return { ...state, menuDrawerOpen: action.payload }

    case TOGGLE_ADMIN_MODE:
      saveReduxState('data', 'adminMode', action.payload)
      console.log('adminMode', action.payload)
      return { ...state, adminMode: action.payload }
    
    case NOTIFICATIONS_ALLOWED:
      console.log('notificationsAllowed', action.payload)
      return { ...state, notificationsAllowed: action.payload }

    default:
      return state
  }
}
