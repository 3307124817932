/**
 * Parses a string of type url params into an object
 *
 * @param urlParams
 *
 * @example
 * input : ?mode=resetPassword&oobCode=Ae5ee&apiKey=AIzaSyC5U&lang=en
 * output : {
 *   apiKey: "AIzaSy",
 *   lang: "en",
 *   mode: "resetPassword",
 *   oobCode: "Ae5ee"
 * }
 */
export const parseParams = urlParams =>
  urlParams.slice(1).split('&').reduce((acc, val) => {
    const arr = val.split('=')
    acc[arr[0]] = arr[1]
    return acc
  }, {})
