import React, { useState } from 'react'
import PropTypes from 'prop-types'
import {
  CardActions, FormControl, MenuItem, Select, Typography, Card, CardContent, CardHeader, Avatar, CardMedia, Grid,
  Button, Box,
} from '@mui/material'
import { useSelector } from 'react-redux'
import InlineEditor from '../_library/InlineEditor'
import { QUALIFICATION_COLLECTION, QUALIFICATION_SUBTYPES, QUALIFICATION_TYPES, USER_COLLECTION } from '../../_constants/globals'
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack'
import { ChevronLeft, ChevronRight } from '@mui/icons-material'
import * as _ from 'lodash'
import { DatePicker } from '@mui/x-date-pickers'
import { serverTimestamp } from 'firebase/firestore'
import { useFirestore } from '../../hooks/useFirestore'


const getQualificationFields = type => {
  switch (type) {
    case QUALIFICATION_TYPES.LICENSE.shortName:
      return [
        { id: 'subtype',
          type: 'select',
          values: QUALIFICATION_SUBTYPES.filter(sub => sub.type === QUALIFICATION_TYPES.LICENSE.id),
        },
        { id: 'identValue', type: 'string' },
        { id: 'attachmentUrl', type: 'file' },
        { id: 'grantDate', type: 'date' },
      ]
    case QUALIFICATION_TYPES.SEP.shortName:
      return [
        { id: 'expireDate', type: 'date' },
        { id: 'attachmentUrl', type: 'file' },
      ]
    case QUALIFICATION_TYPES.VISITE.shortName:
      return [
        { id: 'subtype',
          type: 'select',
          values: QUALIFICATION_SUBTYPES.filter(sub => sub.type === QUALIFICATION_TYPES.VISITE.id),
        },
        { id: 'expireDate', type: 'date' },
        { id: 'grantDate', type: 'date' },
        { id: 'attachmentUrl', type: 'file' },
      ]
    case QUALIFICATION_TYPES.FFA.shortName:
      return [
        { id: 'identValue', type: 'string' },
        { id: 'club', type: 'string' },
        { id: 'expireDate', type: 'date' },
        { id: 'attachmentUrl', type: 'file' },
      ]
    case QUALIFICATION_TYPES.FCL800.shortName:
    case QUALIFICATION_TYPES.BFV1.shortName:
    case QUALIFICATION_TYPES.BFV2.shortName:
    case QUALIFICATION_TYPES.BFV3.shortName:
      return [
        { id: 'attachementUrl', type: 'file' },
      ]
    default:
      return []
  }
}

const getExtension = url => {
  const urlObject = new URL(url)
  const filename = urlObject.pathname.split('/').pop()
  return filename.split('.')[1].toLowerCase()
}

const QualificationCard = ({ qualification }) => {
  
  const profile = useSelector(state => state.profile.data)
  const qualificationHooks = useFirestore(QUALIFICATION_COLLECTION)
  const userHooks = useFirestore(USER_COLLECTION)
  
  const [numPages, setNumPages] = useState(null)
  const [pageNumber, setPageNumber] = useState(1)
  
  return (
    <Card variant='outlined' sx={{ minWidth: 275, mb: 2 }}>
      <CardHeader
        avatar={
          <Avatar aria-label={qualification.type?.name} sx={{ bgcolor: qualification.checkerRef ? 'green.500' : 'red.500' }}>V</Avatar>
        }
        title={qualification.type?.name}
        subheader={qualification.user?.firstname + ' ' + qualification.user?.lastname}
      />
      {qualification.attachmentUrl
        && ['jpg', 'jpeg', 'svg', 'png'].includes(getExtension(qualification.attachmentUrl))
        && <a href={qualification.attachmentUrl} target='_blank' rel='noopener noreferrer'>
          <CardMedia
            image={qualification.attachmentUrl}
            title='attachment'
            sx={{
              height: 0,
              paddingTop: '56.25%', // 16:9
              cursor: 'zoom-in',
              '& canvas': {
                margin: 'auto',
              },
            }}
          />
        </a>}
      {qualification.attachmentUrl
        && (getExtension(qualification.attachmentUrl) === 'pdf')
        && <CardMedia
          component={() => <>
            <Box component='a' href={qualification.attachmentUrl} target='_blank' rel='noopener noreferrer' sx={{
              height: 0,
              paddingTop: '56.25%', // 16:9
              cursor: 'zoom-in',
              '& canvas': {
                margin: 'auto',
              },
            }}>
              <Document file={qualification.attachmentUrl} onLoadSuccess={({ numPages }) => setNumPages(numPages)} >
                <Page pageNumber={pageNumber} />
              </Document>
            </Box>
            <Box sx={{ textAlign: 'center', '& p': { display: 'inline-block' } }}>
              <Button disabled={pageNumber <= 1} onClick={() => setPageNumber(num => num - 1)}><ChevronLeft /></Button>
              <p>Page {pageNumber} of {numPages}</p>
              <Button disabled={pageNumber >= numPages} onClick={() => setPageNumber(num => num + 1)}><ChevronRight /></Button>
            </Box>
          </>}
          title='attachment'
        />}
      <CardContent>
        {qualification.type && getQualificationFields(qualification.type.shortName).map(field =>
          <Grid key={field.id} item xs={12} mb={1}>
            {field.type === 'string' && <InlineEditor title={field.id} value={qualification[field.id]} handleSubmit={val => qualificationHooks.updateDoc(qualification.id, { [field.id]: val })} />}
            {field.type === 'select' && <>
              <Typography color='textSecondary' gutterBottom display='inline-block' lineHeight='20px' width={200} sx={{ color: 'rgba(0,0,0,.54)' }}>
                {field.id}
              </Typography>
              <FormControl variant='outlined'>
                <Select
                  variant='standard'
                  value={_.find(QUALIFICATION_SUBTYPES,  { id: qualification.subtype }) || 'no'}
                  onChange={e => qualificationHooks.updateDoc(qualification.id, { [field.id]: e.target.value.id })}
                  margin='dense'>
                  <MenuItem value='no'><em>Aucune license</em></MenuItem>
                  {field.values.map(val => <MenuItem key={val.id} value={val}>{val.label}</MenuItem>)}
                </Select>
              </FormControl>
            </>}
            {field.type === 'date' && <>
              <Typography color='textSecondary' gutterBottom display='inline-block' lineHeight='20px' width={200} sx={{ color: 'rgba(0,0,0,.54)' }}>
                {field.id}
              </Typography>
              <DatePicker
                inputFormat='dd-MM-yyyy'
                margin='dense'
                id={qualification.type.shortName + '-' + field.id}
                value={qualification[field.id] || null}
                onChange={(val, str) => /\d{2}-\d{2}-\d{4}/g.test(str) && qualificationHooks.updateDoc(qualification.id, { [field.id]: val })}
              />
            </>}
          </Grid>,
        )}
      </CardContent>
      <CardActions style={{ float: 'right' }}>
        {qualification.checkingDate
          ? <Button size='large' color='secondary' variant='contained' onClick={() => qualificationHooks.updateDoc(qualification.id, { checkerRef: null, checkingDate: null })}>
            Invalider
          </Button>
          : <Button
            size='large'
            color='primary'
            variant='contained'
            onClick={() => qualificationHooks.updateDoc(qualification.id, { checkerRef: userHooks.getDocRef(profile.id), checkingDate: serverTimestamp() })}
          >
            Valider
          </Button>
        }
      </CardActions>
    </Card>
  )
}

QualificationCard.propTypes = {
  qualification: PropTypes.object.isRequired,
}

export default QualificationCard
