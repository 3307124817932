import React, { useMemo, useState } from 'react'
import { Card, CardContent, Typography, FormControl, Switch, Grid, TextField, Box } from '@mui/material'
import { useSelector } from 'react-redux'
import InlineEditor from '../_library/InlineEditor'
import AircraftAvatarEditor from './AircraftAvatarEditor'
import { isValid } from 'date-fns'
import { DatePicker } from '@mui/x-date-pickers'
import { AIRCRAFT_COLLECTION } from '../../_constants/globals'
import { useFirestore } from '../../hooks/useFirestore'


const AircraftManagementPanel = () => {
  
  const aircraftHooks = useFirestore(AIRCRAFT_COLLECTION)
  const dbAircrafts = aircraftHooks.getDocs()
  const selectedAircraftId = useSelector(state => state.data.selectedAircraftId)

  const [submitted, setSubmitted] = useState(false)

  const aircraft = useMemo(dbAircrafts?.find(a => a.id === selectedAircraftId), [dbAircrafts, selectedAircraftId])
  
  const handleSwitch = event => {
    setSubmitted(true)
    aircraftHooks.updateDoc(aircraft.id, {
      [event.target.name]: event.target.checked,
    }).then(() => setSubmitted(false))
  }
  
  const updateAircraftAction = data => aircraftHooks.updateDoc(aircraft.id, data)

  return <Box sx={{ maxWidth: '900px', p: 2, mx: 'auto' }}>
    {aircraft
      ? <>
        <Typography variant='body2' color='textSecondary' mb={1}>
          {aircraft.type} - {aircraft.name}
        </Typography>
        <Card id='general' variant='outlined' sx={{ minWidth: '275px', mb: 2 }}>
          <CardContent>
            <Grid item xs={12} sx={{ mb: 1 }}>
              <InlineEditor title="Type d'avion" value={aircraft.type} handleSubmit={type => updateAircraftAction({ type })} />
            </Grid>
            <Grid item xs={12} sx={{ mb: 1 }}>
              <InlineEditor title='Immatriculation' value={aircraft.name} handleSubmit={name => updateAircraftAction({ name })} />
            </Grid>
            <Grid item xs={12} sx={{ mb: 1 }}>
              <Typography color='rgba(0,0,0,.54)' display='inline-block' lineHeight='20px' width='200px'>
                Activé
              </Typography>
              <FormControl variant='outlined'>
                <Switch
                  checked={aircraft.activated}
                  onChange={handleSwitch}
                  name='activated'
                  inputProps={{ 'aria-label': 'secondary checkbox' }}
                  disabled={submitted}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sx={{ mb: 1 }}>
              <Typography color='rgba(0,0,0,.54)' display='inline-block' lineHeight='20px' width='200px'>
                Réservable
              </Typography>
              <FormControl variant='outlined'>
                <Switch
                  checked={aircraft.bookable}
                  onChange={handleSwitch}
                  name='bookable'
                  inputProps={{ 'aria-label': 'secondary checkbox' }}
                  disabled={submitted}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sx={{ mb: 1 }}>
              <AircraftAvatarEditor aircraft={aircraft} />
            </Grid>
            <InlineEditor
              title='Commentaire'
              value={aircraft.comments}
              handleSubmit={comments => updateAircraftAction({ comments })}
              multiline
              rows={4}
            />
          </CardContent>
        </Card>

        <Typography variant='body2' color='textSecondary' mb={1}>Suivi méca</Typography>
        <Card id='mechanics' variant='outlined' sx={{ minWidth: '275px', mb: 2 }}>
          <CardContent>
            <Grid item xs={12} sx={{ mb: 1 }}>
              <InlineEditor
                title='Dernière visite (compteur)'
                value={aircraft.followUp?.lastCheckupCounter}
                handleSubmit={val => Number(val) && updateAircraftAction({
                  followUp: {
                    ...aircraft.followUp,
                    lastCheckupCounter: Number(val),
                  },
                })}
                type='number'
                inputProps={{ step: aircraft && aircraft.price.float || .01 }}
              />
            </Grid>
            <Grid item xs={12} sx={{ mb: 1 }}>
              <Typography color='rgba(0,0,0,.54)' display='inline-block' lineHeight='20px' width='200px'>
                Dernière visite (date)
              </Typography>
              <DatePicker
                inputFormat='dd-MM-yyyy'
                margin='dense'
                id='birthdate'
                value={aircraft.followUp?.lastCheckupDate ? aircraft.followUp.lastCheckupDate : null}
                onChange={val => isValid(val) && updateAircraftAction({
                  followUp: {
                    ...aircraft.followUp,
                    lastCheckupDate: val,
                  },
                })}
                renderInput={params => <TextField {...params} />}
              />
            </Grid>
            <Grid item xs={12} sx={{ mb: 1 }}>
              <InlineEditor
                title='Prochaine visite (compteur)'
                value={aircraft.followUp?.nextCheckupCounter}
                handleSubmit={val => Number(val) && updateAircraftAction({
                  followUp: {
                    ...aircraft.followUp,
                    nextCheckupCounter: Number(val),
                  },
                })}
                type='number'
                inputProps={{ step: aircraft && aircraft.price.float || .01 }}
              />
            </Grid>
            <Grid item xs={12} sx={{ mb: 1 }}>
              <InlineEditor
                title='Prochaine visite (type de visite)'
                value={aircraft.followUp?.nextCheckupKind}
                handleSubmit={val => updateAircraftAction({
                  followUp: {
                    ...aircraft.followUp,
                    nextCheckupKind: val,
                  },
                })}
              />
            </Grid>
            <Grid item xs={12} sx={{ mb: 1 }}>
              <InlineEditor
                title='Tolérance (heures)'
                value={aircraft.followUp?.margin}
                handleSubmit={val => Number(val) && updateAircraftAction({
                  followUp: {
                    ...aircraft.followUp,
                    margin: Number(val),
                  },
                })}
                type='number'
                inputProps={{ step: 1 }}
              />
            </Grid>
          </CardContent>
        </Card>
      </>
      : <div style={{ width: 'fit-content', margin: 'auto' }}>Pas d&apos;avion sélectionné</div>}
  </Box>
}

export default AircraftManagementPanel
