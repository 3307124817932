import { differenceInDays, format, isSameDay, isSameMonth } from 'date-fns'
import { fr } from 'date-fns/locale'

export const expireColor = date => {
  if (!date) return ''
  const differenceInCalendarDays = differenceInDays(date, new Date())
  if (differenceInCalendarDays < 0) return '#F00'
  else if (differenceInCalendarDays > 0 && differenceInCalendarDays < 60) return 'orange'
  else return '#28a752'
}

export const printEventDuration = (firstDay, lastDay) => {
  if (isSameDay(firstDay, lastDay))
    return `le ${format(firstDay.toDate(), 'dd MMMM', { locale: fr })}`
  if (isSameMonth(firstDay.toDate(), lastDay.toDate()))
    return `du ${format(firstDay.toDate(), 'dd')} au ${format(lastDay.toDate(), 'dd MMMM', { locale: fr })}`
  else
    return `du ${format(firstDay.toDate(), 'dd MMMM', { locale: fr })} au ${format(lastDay.toDate(), 'dd MMMM', { locale: fr })}`
}
