import React, { useState } from 'react'
import { DialogTitle, Dialog, SpeedDial, SpeedDialAction, SpeedDialIcon, Typography } from '@mui/material'
import DaySelector from './DaySelector'
import { Flight } from '@mui/icons-material'
import NewBookingForm from './NewBookingForm'
import PlanningTable from './PlanningTable'
import NewFlightDialog from '../flights/NewFlightDialog'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCalendarPlus, faUserFriends } from '@fortawesome/free-solid-svg-icons'
import { parseParams } from '../../_helpers/urlHelper'
import { parse, format } from 'date-fns'
import { useLocation } from 'react-router'
import { fr } from 'date-fns/locale'
import { useSelector } from 'react-redux'
import { ROLE_INSTRUCTOR } from '../../_constants/globals'
import WriteAvailabilityDialog from './WriteAvailabilityDialog'
import useAuth from '../../hooks/useAuth'


const PlanningPanel = () => {
  
  const location = useLocation()
  const profile = useAuth().getProfile()
  const adminMode = useSelector(state => state.data.adminMode)
  
  const [day, setDay] = useState(parseParams(location.search).date ? parse(parseParams(location.search).date, 'dd-MM-yyyy', new Date()) : new Date())
  const [speedDialOpen, setSpeedialOpen] = useState(false)
  const [newFlightOpen, setNewFlightOpen] = useState(false)
  const [newBookingOpen, setNewBookingOpen] = useState(false)
  const [newAvailabilityOpen, setNewAvailabilityOpen] = useState(false)

  return (
    <>
      <DaySelector day={day} setDay={setDay} sx={{ mx: 'auto', my: 1 }} />
      <Typography variant='caption' ml={1}>{format(day, 'EEEE', { locale: fr })}</Typography>
      <PlanningTable day={day} />
      <SpeedDial
        ariaLabel='speedDial'
        icon={<SpeedDialIcon />}
        onClose={() => setSpeedialOpen(false)}
        onClick={() => setSpeedialOpen(true)}
        open={speedDialOpen}
        FabProps={{ color: 'secondary' }}
        sx={{
          right: theme => theme.spacing(3),
          bottom: theme => theme.spacing(3),
          position: 'fixed',
        }}
      >
        <SpeedDialAction
          icon={<Flight />}
          tooltipTitle='Saisir un vol'
          onClick={() => setNewFlightOpen(true)}
        />
        <SpeedDialAction
          icon={<FontAwesomeIcon icon={faCalendarPlus} style={{ fontSize: '1.1rem' }} />}
          tooltipTitle='Nouvelle réservation'
          onClick={() => setNewBookingOpen(true)}
        />
        {(adminMode || profile.roles?.includes(ROLE_INSTRUCTOR)) && <SpeedDialAction
          icon={<FontAwesomeIcon icon={faUserFriends} style={{ fontSize: '1.1rem' }} />}
          tooltipTitle='Nouvelle dispo instructeur'
          onClick={() => setNewAvailabilityOpen(true)}
        />}
      </SpeedDial>
      <Dialog open={newBookingOpen} onClose={() => setNewBookingOpen(false)} aria-labelledby='form-dialog-title'>
        <DialogTitle id='form-dialog-title'>Nouvelle réservation</DialogTitle>
        <NewBookingForm onClose={() => setNewBookingOpen(false)} />
      </Dialog>
      <NewFlightDialog open={newFlightOpen} onClose={() => setNewFlightOpen(false)} />
      {day && newAvailabilityOpen && <WriteAvailabilityDialog open dayInit={day} onClose={() => setNewAvailabilityOpen(false)} />}
    </>
  )
}

export default PlanningPanel
