import React, { useEffect, useState } from 'react'
import { FlightTakeoff } from '@mui/icons-material'
import FlightLogTable from './FlightLogTable'
import NewFlightDialog from './NewFlightDialog'
import { Box, SpeedDial, SpeedDialAction, SpeedDialIcon, Typography } from '@mui/material'
import { useSelector } from 'react-redux'


const FlightLogPanel = () => {
  
  const selectedAircraft = useSelector(state => state.data.selectedAircraft)

  const [speedDialOpen, setSpeedialOpen] = useState(false)
  const [newFlightOpen, setNewFlightOpen] = useState(false)
  const [page, setPage] = useState(0)

  useEffect(() => setPage(selectedAircraft?.currentLogbookPage || 0), [selectedAircraft])

  return (
    <Box sx={{ pb: '100px' }}>
      {selectedAircraft
        ? <FlightLogTable page={page} changePage={setPage}/>
        : <Typography variant='button' component='div' textAlign='center' mt={4}>selectionne un avion ci-dessus</Typography>
      }
      <SpeedDial
        ariaLabel='speedDial'
        icon={<SpeedDialIcon />}
        onClose={() => setSpeedialOpen(false)}
        onOpen={() => setSpeedialOpen(true)}
        open={speedDialOpen}
        FabProps={{ color: 'secondary' }}
        sx={{
          position: 'fixed',
          right: theme => theme.spacing(3),
          bottom: theme => theme.spacing(3),
        }}
      >
        <SpeedDialAction
          icon={<FlightTakeoff />}
          tooltipTitle='Saisir un vol'
          onClick={() => setNewFlightOpen(true)}
        />
      </SpeedDial>
      {newFlightOpen && <NewFlightDialog open onClose={() => setNewFlightOpen(false)} />}
    </Box>
  )
}

export default FlightLogPanel
