/* eslint-disable react/display-name */
import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { Avatar, Box, Card, CardContent, TextField, Tooltip } from '@mui/material'
import Loading from '../_library/Loading'
import useAuth from '../../hooks/useAuth'
import { format } from 'date-fns'
import Legend from './Legend'
import { getObjectiveSymbol } from '../../_helpers/dataHelper'
import Grader from './Grader'
import { ScrollSyncPane } from 'react-scroll-sync'


const HEADCOL_WIDTH = '280px'
const headcolStyle = {
  position: 'absolute',
  width: HEADCOL_WIDTH,
  left: theme => theme.spacing(2),
  top: 'auto',
  mt: '-1px',
  whiteSpace: 'nowrap',
  overflowX: 'hidden',
  '&:hover': {
    overflowX: 'visible',
  },
}
const getItemColor = grade => {
  switch (grade) {
    case 'knowledge': return 'info.light'
    case 'realised': return 'info.main'
    case 'skill': return 'success.main'
    case 'trainingNeeded': return 'error.main'
  }
}

const ItemsCard = ({ items, classname, lessons, type, editingLesson, setEditingLesson }) => {
  
  const profile = useAuth().getProfile()
  
  const lessonsWithNew = useMemo(() => {
    if (editingLesson?.id === 'new'
      && ((type === 'theoretical' && editingLesson.withTheory) || (type === 'practical' && editingLesson.withPractice)))
      return [...lessons, editingLesson]
    else return lessons
  }, [lessons, editingLesson, type])
  
  return (
    <Card className={classname} id={type + 'Card'} sx={{ mb: 4, position: 'relative' }}>
      <CardContent sx={{ pt: '60px', pr: '50px' }}>
        <Legend />
        {items && lessonsWithNew
          ? <ScrollSyncPane group='vertical'>
            <Box sx={{ p: 0, pr: '30px', overflowX: 'scroll', ml: HEADCOL_WIDTH }}>
              <Box component='table' sx={{
                borderCollapse: 'collapse',
                borderSpacing: 0,
                margin: 0,
              }}>
                <thead>
                  <Box component='tr' sx={{ height: '80px' }}>
                    <Box component='td' sx={headcolStyle}>
                      {type === 'theoretical' ? 'Items théoriques' : 'Items pratiques'}
                    </Box>
                    <Box component='th' sx={{ maxWidth: '20px' }}><Box sx={{
                      width: 'fit-content',
                      transform: 'translateY(0px) translateX(0px) rotate(-45deg)',
                      whiteSpace: 'nowrap',
                    }}>Objectif</Box></Box>
                    {lessons?.map(lesson => editingLesson?.id !== lesson.id &&
                      <Box component='th' key={lesson.id} sx={{ maxWidth: '20px' }}><Box sx={{
                        width: 'fit-content',
                        transform: 'translateY(0px) translateX(0px) rotate(-45deg)',
                        whiteSpace: 'nowrap',
                      }}>
                        {format(lesson.date, 'dd-MM-yyyy')}
                      </Box></Box>,
                    )}
                  </Box>
                </thead>
                <tbody>
                  {items.map(item =>
                    <tr key={item.id}>
                      <Box component='td' sx={headcolStyle}>
                        {lessonsWithNew?.some(t => t.gradedItems && t.gradedItems.find(i => i.id === item.id)?.grade === item.objective) && '✔️ '}
                        {item.name}
                      </Box>
                      <td>
                        {getObjectiveSymbol(item.objective)}
                      </td>
                      {lessonsWithNew?.map(lesson =>
                        editingLesson?.id === lesson.id && editingLesson.gradedItems
                          ? <Box
                            component='td'
                            key={lesson.id}
                            sx={{
                              border: lesson.selectedItemIds?.includes(item.id) && 'solid black 2px',
                              display: 'flex',
                              width: 'max-content',
                            }}
                          >
                            <Grader
                              grade={editingLesson.gradedItems.find(i => i.id === item.id)?.grade}
                              setGrade={val => {
                                const existingItem = editingLesson.gradedItems.find(i => i.id === item.id)
                                let gradedItems
                                if (existingItem && existingItem.grade === val)
                                  gradedItems = editingLesson.gradedItems.filter(i => i.id !== item.id)
                                else if (existingItem)
                                  gradedItems = [...editingLesson.gradedItems.filter(i => i.id !== item.id), { ...existingItem, grade: val }]
                                else
                                  gradedItems = [...editingLesson.gradedItems, { id: item.id, type, grade: val }]
                                return setEditingLesson({ ...editingLesson, gradedItems })
                              }}
                              objective={item.objective}
                            />
                            <TextField
                              value={editingLesson.gradedItems.find(i => i.id === item.id)?.comment || ''}
                              onChange={e => {
                                const existingItem = editingLesson.gradedItems.find(i => i.id === item.id)
                                let gradedItems
                                if (existingItem && !e.target.value && !existingItem.grade)
                                  gradedItems = editingLesson.gradedItems.filter(i => i.id !== item.id)
                                else if (existingItem)
                                  gradedItems = [...editingLesson.gradedItems.filter(i => i.id !== item.id), { ...existingItem, comment: e.target.value }]
                                else
                                  gradedItems = [...editingLesson.gradedItems, { id: item.id, type, comment: e.target.value }]
                                return setEditingLesson({ ...editingLesson, gradedItems })
                              }}
                              variant='outlined'
                              placeholder='commentaire'
                              inputProps={{ style: { padding: '2px 5px' } }}
                            />
                          </Box>
                          : <Tooltip
                            key={lesson.id}
                            title={(lesson.gradedItems && lesson.gradedItems.find(i => i.id === item.id)?.comment) || ''}
                            disableHoverListener={!lesson.gradedItems || !lesson.gradedItems?.find(i => i.id === item.id)?.comment}
                          >
                            <Box
                              component='td'
                              onClick={() => lesson.instructorRef?.id === profile.id && setEditingLesson(lesson)}
                              sx={{
                                bgcolor: !lesson.draft && !lesson.gradedItems.some(i => i.type === type) && 'grey.100',
                                textAlign: 'center',
                                cursor: lesson.instructorRef?.id === profile.id ? 'pointer' : 'default',
                                position: 'relative',
                                borderLeft: lesson.selectedItemIds?.includes(item.id) ? 'solid 2px' : 'solid 1px',
                                borderRight: lesson.selectedItemIds?.includes(item.id) ? 'solid 2px' : 'solid 1px',
                                borderTop: lesson.selectedItemIds?.includes(item.id) ? 'solid 2px' : 'solid 1px',
                                borderBottom: lesson.selectedItemIds?.includes(item.id) ? 'solid 2px' : 'solid 1px',
                                borderColor: lesson.selectedItemIds?.includes(item.id) ? 'black' : 'grey.300',
                                color: lesson.gradedItems && getItemColor(lesson.gradedItems?.find(i => i.id === item.id)?.grade),
                                '& tbody tr:first-of-type td.clickable': {
                                  borderTop: 'solid 2px',
                                  borderColor: 'grey.300',
                                },
                                '& tbody tr:last-child td.clickable': {
                                  borderBottom: 'solid 2px',
                                  borderColor: 'grey.300',
                                },
                              }}
                            >
                              {lesson.gradedItems && getObjectiveSymbol(lesson.gradedItems?.find(i => i.id === item.id)?.grade)}
                              {lesson.gradedItems?.find(i => i.id === item.id)?.comment && <Box sx={{
                                position: 'absolute',
                                zIndex: 1,
                                height: 0,
                                width: 0,
                                border: 'solid transparent',
                                top: 0,
                                right: 0,
                                borderLeft: 'solid',
                                borderLeftColor: 'warning.main',
                                borderLeftWidth: 5,
                                borderTopWidth: 5,
                                borderBottomWidth: 5,
                                transform: 'translateX(3px) translateY(-4px) rotate(-45deg)',
                              }} />}
                            </Box>
                          </Tooltip>,
                      )}
                    </tr>,
                  )}
                </tbody>
                <tfoot>
                  <tr>
                    <Box component='td' sx={{ ...headcolStyle, height: '33px' }} />
                    <td />
                    {lessonsWithNew?.map(training => training.instructor &&
                      <Tooltip title={training.instructor?.firstname + ' ' + training.instructor?.lastname} key={training.id}>
                        <td>
                          <Avatar alt='instructor avatar' src={training.instructor?.photoUrl} sx={{ width: 30, height: 30 }} />
                        </td>
                      </Tooltip>,
                    )}
                  </tr>
                </tfoot>
              </Box>
            </Box>
          </ScrollSyncPane>
          : <Loading />
        }
      </CardContent>
    </Card>
  )
}

ItemsCard.propTypes = {
  items: PropTypes.array,
  classname: PropTypes.string,
  lessons: PropTypes.array,
  type: PropTypes.string.isRequired,
  editingLesson: PropTypes.object,
  setEditingLesson: PropTypes.func.isRequired,
}

export default ItemsCard
