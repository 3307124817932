import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { compose } from 'recompose'
import withStyles from '@mui/styles/withStyles'
import clsx from 'clsx'


const styles = theme => ({
  root: {
    width: '100%',
    height: 20,
  },
  graph: {
    width: '100%',
    height: '100%',
    position: 'relative',
    border: 'solid 1px',
    borderColor: theme.palette.primary.main,
  },
  progress: {
    height: '100%',
    position: 'absolute',
    backgroundColor: theme.palette.primary.main,
  },
  text: {
    position: 'absolute',
    fontSize: '80%',
    color: theme.palette.primary.main,
  },
})

export const CompletenessGraph = ({ classes, className, completeness: { validatedItems, total } }) => {

  const pourcent = useMemo(() => validatedItems / total * 100, [validatedItems, total])

  return (
    <div className={clsx(classes.root, className)}>
      <div className={classes.graph}>
        <div className={classes.progress} style={{ width: `${pourcent}%` }} />
        <div className={classes.text} style={pourcent < 50
          ? { left: `calc(${pourcent}% + 8px)` }
          : { right: `calc(${100 - pourcent}% + 8px)`, color: 'white' }}>
          {`${validatedItems} / ${total}`}
        </div>
      </div>
    </div>
  )
}

CompletenessGraph.propTypes = {
  classes: PropTypes.object.isRequired,
  className: PropTypes.string,
  completeness: PropTypes.object.isRequired,
}

export default compose(
  withStyles(styles),
)(CompletenessGraph)
