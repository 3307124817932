import React, { useMemo, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { Dialog, DialogTitle, useTheme, useMediaQuery, IconButton, DialogContent, DialogActions } from '@mui/material'
import WriteActivityForm from './WriteActivityForm'
import { Close } from '@mui/icons-material'
import CTAButton from '../_library/CTAButton'
import { useFirestore } from '../../hooks/useFirestore'
import { ACTIVITY_COLLECTION } from '../../_constants/globals'


export const WriteActivityDialog = ({ open, onClose, id }) => {
  
  const theme = useTheme()
  const isUpMd = useMediaQuery(theme.breakpoints.up('md'))
  const formRef = useRef()
  const activityHooks = useFirestore(ACTIVITY_COLLECTION)
  const dbActivities = activityHooks.getDocs()
  
  const [submitted, setSubmitted] = useState(false)
  
  const activity = useMemo(dbActivities?.find(b => b.id === id), [dbActivities, id])
  
  return (
    <Dialog
      open={open || false}
      onClose={onClose}
      aria-labelledby='form-dialog-title'
      fullScreen={!isUpMd}
      sx={{
        '& .MuiDialog-paperWidthSm': {
          maxWidth: 700,
        },
        '& .MuiInput-underline::before': {
          borderBottomColor: 'rgba(0,0,0,.1)',
        },
      }}
    >
      <DialogTitle>
        {!id && 'Créer une activité'}
        &nbsp;
        <IconButton
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: 'grey.500',
          }}
        >
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent sx={{ px: 0 }}>
        <WriteActivityForm formRef={formRef} setSubmitted={setSubmitted} onComplete={onClose} activity={activity} />
      </DialogContent>
      <DialogActions>
        <CTAButton type='submit' fullWidth onClick={() => formRef.current.requestSubmit()} disabled={submitted}>
          {id ? 'Mettre à jour' : 'Créer'}
        </CTAButton>
      </DialogActions>
    </Dialog>
  )
}

WriteActivityDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  id: PropTypes.string,
}

export default WriteActivityDialog
