import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Button, Card, CardActions, CardContent, CardHeader, Divider, Grid, Typography } from '@mui/material'
import Contact from './Contact'
import AddUserDialog from './AddUserDialog'
import { useSelector } from 'react-redux'


const UserCard = ({ sx }) => {
  const { id, users } = useSelector(state => state.data.selectedActivity || {})
  
  const [addUserDialogOpen, setAddUserDialogOpen] = useState(false)
  
  return (
    <Card sx={sx}>
      <CardHeader title='Liste des participants' />
      <CardContent>
        <Grid container>
          <Grid item xs={4}>
            <Typography component='div' fontWeight='bold' textAlign='center'>{users?.filter(u => u.role === 'Instructeur').length || 0}</Typography>
            <Typography component='div' variant='caption' textAlign='center'>INSTRUCTEURS</Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography component='div' fontWeight='bold' textAlign='center'>{users?.filter(u => ['Organisateur', 'Pilote'].includes(u.role)).length || 0}</Typography>
            <Typography component='div' variant='caption' textAlign='center'>PILOTES</Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography component='div' fontWeight='bold' textAlign='center'>{users?.filter(u => ['MEG', 'VD'].includes(u.role)).length || 0}</Typography>
            <Typography component='div' variant='caption' textAlign='center'>INVITÉS</Typography>
          </Grid>
        </Grid>
        <Divider sx={{ my: 2 }} />
        {users?.map(u => <Contact key={u.id} userId={u.id} role={u.role} />)}
      </CardContent>
      <CardActions>
        <Button fullWidth onClick={() => setAddUserDialogOpen(true)}>Ajouter un participant</Button>
      </CardActions>
      {addUserDialogOpen && <AddUserDialog open onClose={() => setAddUserDialogOpen(false)} activityId={id} />}
    </Card>
  )
}

UserCard.propTypes = {
  sx: PropTypes.object,
}

export default UserCard
