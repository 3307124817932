import React from 'react'
import PropTypes from 'prop-types'
import { TextField } from '@mui/material'
import withStyles from '@mui/styles/withStyles'
import { compose } from 'recompose'

const styles = theme => ({
  input: {
    '& $notchedOutline': {
      borderColor: theme.palette.primary.main,
    },
  },
  notchedOutline: {},
  iconButton: {
    padding: 10,
  },
})

const OutlinedTextField = props => {
  const {
    id,
    label,
    name,
    required,
    disabled,
    classes,
    value,
    onChange,
    className,
    type,
    error,
    iterator,
    select,
    rowsMax,
    multiline,
    onKeyPress,
    autoFocus,
    endAdornment,
    helperText,
    min,
    max,
    step,
  } = props
  return (
    <TextField
      label={label}
      id={id}
      select={select}
      autoFocus={autoFocus}
      multiline={multiline}
      disabled={disabled}
      name={name}
      onKeyPress={onKeyPress}
      maxRows={rowsMax}
      error={error}
      required={required}
      value={value}
      type={type}
      onChange={onChange}
      className={className}
      helperText={helperText}
      InputProps={{
        classes: {
          root: classes.input,
          notchedOutline: classes.notchedOutline,
        },
        endAdornment,
      }}
      // eslint-disable-next-line
      inputProps={{
        min,
        max,
        step,
      }}
      margin='normal'
      variant='outlined'
    >
      {iterator}
    </TextField>
  )
}

OutlinedTextField.propTypes = {
  defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  disabled: PropTypes.bool,
  error: PropTypes.bool,
  multiline: PropTypes.bool,
  id: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.instanceOf(Date)]),
  label: PropTypes.node,
  name: PropTypes.string,
  step: PropTypes.string,
  min: PropTypes.string,
  max: PropTypes.string,
  rowsMax: PropTypes.string,
  required: PropTypes.bool,
  select: PropTypes.bool,
  className: PropTypes.string,
  classes: PropTypes.object,
  type: PropTypes.string,
  onChange: PropTypes.func,
  onKeyPress: PropTypes.func,
  iterator: PropTypes.array,
  autoFocus: PropTypes.bool,
  endAdornment: PropTypes.object,
  helperText: PropTypes.string,
}

OutlinedTextField.defaultProps = {
  required: false,
  error: false,
}

export default compose(
  withStyles(styles),
)(OutlinedTextField)
