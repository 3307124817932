import React, { useEffect } from 'react'
import { Card, CardContent, CardHeader, Skeleton } from '@mui/material'
import { PlayArrow } from '@mui/icons-material'
import { ACTIVITIES } from '../../_constants/routes'
import ActivityButton from './ActivityButton'
import { useFirestore } from '../../hooks/useFirestore'


export const ActivityCard = () => {
  
  const activityHooks = useFirestore(ACTIVITIES, { storeAs: 'nextActivities' })
  const activities = activityHooks.getDocs()
  
  useEffect(() => {
    activityHooks.listen({
      orderBy: [['lastDay', 'desc']],
      where: [['lastDay', '>=', new Date()]],
      limit: 4,
    })
    return activityHooks.unsubscribe
  }, [])
  
  if (!activities) return <Skeleton height={460} variant='rectangular' sx={{ borderRadius: 1 }} />
  return (
    <Card sx={{ minWidth: 275, mb: 2, p: 1, position: 'relative', height: 460 }}>
      <CardHeader avatar={<PlayArrow />} title='Prochain stages' sx={{ color: 'grey.600' }} />
      <CardContent>
        {activities?.reverse().map(activity => <ActivityButton key={activity.id} {...activity} />)}
      </CardContent>
    </Card>
  )
}

export default ActivityCard
