import React, { useEffect, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { Button, Collapse, Fab, Grid, Switch, TextField, Typography } from '@mui/material'
import { Add, ArrowDropDown, ArrowDropUp } from '@mui/icons-material'
import { selectLessonId } from '../../store/actions/dataActions'
import { parseParams } from '../../_helpers/urlHelper'
import { omit } from 'lodash'
import DeleteButton from '../_library/DeleteButton'
import FlightConnector from './FlightConnector'
import { FLIGHT_COLLECTION, NOTIFICATION_COLLECTION, PB_LESSON_COLLECTION, PROGRESS_BOOK_COLLECTION, USER_COLLECTION } from '../../_constants/globals'
import { DatePicker } from '@mui/x-date-pickers'
import { useLocation } from 'react-router'
import { useFirestore } from '../../hooks/useFirestore'
import useAuth from '../../hooks/useAuth'


const SelectedLessonManager = ({ progressBook, lessonsWithItems, scrollTo, editingLesson, setEditingLesson }) => {
  
  const location = useLocation()
  const profile = useAuth().getProfile()
  const notificationHooks = useFirestore(NOTIFICATION_COLLECTION)
  const dbNotifications = notificationHooks.getDocs()
  const bookHooks = useFirestore(PROGRESS_BOOK_COLLECTION)
  const userHooks = useFirestore(USER_COLLECTION)
  const lessonHooks = useFirestore(PB_LESSON_COLLECTION)
  
  const [expand, setExpand] = useState(false)
  const [submitted, setSubmitted] = useState(false)
  const [errors, setErrors] = useState({})

  const notifications = useMemo(() => dbNotifications?.filter(n => n.params?.progressBookRef?.id === progressBook?.id), [dbNotifications])
  const newLessonTemplate = useMemo(() => ({
    id: 'new',
    withTheory: true,
    withPractice: true,
    archived: false,
    progressBookRef: bookHooks.getDocRef(progressBook.id),
    pilotRef: progressBook.pilotRef,
    instructorRef: userHooks.getDocRef(profile.id),
    gradedItems: [],
    generalImpression: {},
  }), [progressBook, profile.id])

  useEffect(() => {
    if (location.search) {
      const { lessonId } = parseParams(location.search)
      const lesson = lessonsWithItems?.find(l => l.id === lessonId)
      if (lesson?.selectedItemIds)
        setEditingLesson(lesson)
    }
  }, [location.search, lessonsWithItems])
  
  useEffect(() => {
    if (typeof editingLesson === 'string') {
      const lessonId = editingLesson
      const lesson = lessonsWithItems?.find(l => l.id === lessonId)
      if (lesson?.selectedItemIds)
        setEditingLesson(lesson)
    }
  }, [editingLesson, lessonsWithItems])
  
  useEffect(() => {
    !editingLesson && setExpand(false)
  }, [editingLesson])

  const handleSubmit = () => {
    setSubmitted(true)
    setErrors({})
    if (editingLesson.gradedItems.some(i => i.type === 'theoretical') && !editingLesson.briefingDuration) {
      setErrors(prev => ({ ...prev, briefingDuration: 'Champs requis' }))
      setSubmitted(false)
    }
    if (!editingLesson.gradedItems.some(i => i.type === 'practical') && !editingLesson.date) {
      setErrors(prev => ({ ...prev, date: 'Champs requis' }))
      setSubmitted(false)
      setExpand(true)
    }
    if (editingLesson.gradedItems.some(i => i.type === 'practical') && !editingLesson.flightId && !editingLesson.flightRef) {
      setErrors(prev => ({ ...prev, flightId: 'Champs requis' }))
      setSubmitted(false)
      setExpand(true)
    }
    if ((editingLesson.gradedItems.some(i => i.type === 'theoretical') && !editingLesson.briefingDuration)
      || (!editingLesson.gradedItems.some(i => i.type === 'practical') && !editingLesson.date)
      || (editingLesson.gradedItems.some(i => i.type === 'practical') && !editingLesson.flightId && !editingLesson.flightRef))
      return
    const response = editingLesson.id === 'new'
      ? lessonHooks.addDoc(omit({ ...editingLesson, draft: false }, ['id']))
      : lessonHooks.updateDoc(editingLesson.id, omit({ ...editingLesson, draft: false }, ['id']))
    return response
      .then(() => Promise.all(notifications?.map(n => notificationHooks.deleteDoc(n.id))))
      .then(() => setEditingLesson(null))
      .catch(err => console.error(err))
      .finally(() => setSubmitted(false))
  }

  const handleDelete = () => {
    setSubmitted(true)
    return lessonHooks.deleteDoc(editingLesson.id)
      .then(() => selectLessonId(null))
      .catch(err => console.error(err))
      .finally(() => setSubmitted(false))
  }

  return editingLesson
    ? <Grid container sx={{
      position: 'fixed',
      bgcolor: 'primary.light',
      borderTopLeftRadius: '10px',
      borderTopRightRadius: '10px',
      bottom: 0,
      ml: -1,
      p: 1,
      width: '-webkit-fill-available',
    }}>
      <Grid item md={3}>
        <div>
          <Switch
            checked={editingLesson.withTheory || false}
            onChange={e => setEditingLesson({
              ...editingLesson,
              withTheory: e.target.checked,
              gradedItems: e.target.checked ? editingLesson.gradedItems : editingLesson.gradedItems.filter(i => i.type === 'practical'),
            })}
            name='briefingSwitch'
          />
          <span onClick={() => scrollTo('theoreticalCard')} style={{ cursor: 'pointer' }}>Briefing</span>
        </div>
        {editingLesson.withTheory && <>
          <Typography variant='body1' style={{ display: 'inline-block' }}>Durée :&nbsp;</Typography>
          <TextField
            value={editingLesson.briefingDuration || ''}
            onChange={e => setEditingLesson({ ...editingLesson, briefingDuration: e.target.value })}
            variant='outlined'
            inputProps={{ style: { padding: '2px 5px' } }}
            error={!!errors.briefingDuration}
            helperText={errors.briefingDuration}
            id='briefingDurationInput'
          />
          <Typography variant='body1'>Items : {editingLesson.gradedItems?.filter(i => i.type === 'theoretical').length || 0}</Typography>
        </>}
      </Grid>
      <Grid item md={3}>
        <div>
          <Switch
            checked={editingLesson.withPractice || false}
            onChange={e => setEditingLesson({
              ...editingLesson,
              withPractice: e.target.checked,
              gradedItems: e.target.checked ? editingLesson.gradedItems : editingLesson.gradedItems.filter(i => i.type === 'theoretical'),
              generalImpression: e.target.checked ? editingLesson.generalImpression : {},
            })}
            name='flightSwitch'
          />
          <span onClick={() => scrollTo('practicalCard')} style={{ cursor: 'pointer' }}>Vol</span>
        </div>
        {editingLesson.withPractice && <>
          <Typography variant='body1'>Items : {editingLesson.gradedItems?.filter(i => i.type === 'practical').length || 0}</Typography>
        </>}
      </Grid>
      <Grid item md={3}>
        <div>
          <Switch
            checked={editingLesson.withPractice || false}
            onChange={e => setEditingLesson({
              ...editingLesson,
              withPractice: e.target.checked,
              gradedItems: e.target.checked ? editingLesson.gradedItems : editingLesson.gradedItems.filter(i => i.type === 'theoretical'),
              generalImpression: e.target.checked ? editingLesson.generalImpression : {},
            })}
          />
          <span onClick={() => scrollTo('generalImpressionCard')} style={{ cursor: 'pointer' }}>Impression générale</span>
          {editingLesson.withPractice && <>
            <Typography variant='body1'>Items : {editingLesson.generalImpression ? Object.keys(editingLesson.generalImpression).length : 0}</Typography>
          </>}
        </div>
      </Grid>
      <Grid item md={3}>
        <Button variant='outlined' onClick={handleSubmit} disabled={submitted} sx={{
          display: 'block',
          my: 1,
          mx: 'auto',
          width: '90%',
          fontWeight: 'bold',
          borderWidth: 2,
          borderColor: 'grey.800',
        }}>Valider</Button>
        <Button variant='outlined' onClick={() => setEditingLesson(null)} disabled={submitted} sx={{
          display: 'block',
          my: 1,
          mx: 'auto',
          width: '90%',
        }}>Annuler</Button>
      </Grid>
      <Collapse in={expand} style={{ width: '100%' }}>
        <Grid item xs={12} container spacing={2}>
          <Grid item md={9}>
            {editingLesson.withPractice
              ? <FlightConnector
                lesson={editingLesson}
                handleChange={(flightId, flights) =>
                  setEditingLesson({
                    ...editingLesson,
                    flightRef: [FLIGHT_COLLECTION, flightId],
                    date: flights.find(f => f.id === flightId)?.startDate,
                  })
                }
                error={!!errors.flightId}
              />
              : <>
                <DatePicker
                  disableToolbar
                  variant='inline'
                  inputFormat='dd-MM-yyyy'
                  margin='dense'
                  label='Date de la session'
                  value={editingLesson.date || null}
                  onChange={val => setEditingLesson({ ...editingLesson, date: val })}
                  slotProps={{ textField: { error: !!errors.date, helperText: errors.date } }}
                  sx={{ display: 'block' }}
                />
              </>
            }
          </Grid>
          <Grid item md={3}>
            {editingLesson.id !== 'new' && <DeleteButton text='Supprimer' deleteAction={handleDelete} sx={{
              display: 'block',
              my: 1,
              mx: 'auto',
              width: '90%',
            }} />}
          </Grid>
          <Grid item md={12}>
            <TextField
              label='Commentaire général'
              value={editingLesson.comment || ''}
              onChange={e => setEditingLesson({ ...editingLesson, comment: e.target.value })}
              multiline
              rows={3}
              variant='outlined'
              fullWidth
            />
          </Grid>
        </Grid>
      </Collapse>
      <Button color='primary' onClick={() => setExpand(prev => !prev)} id='expandButton' sx={{
        width: '100%',
        height: '20px',
        color: 'white',
      }}>
        {expand
          ? <ArrowDropDown />
          : <ArrowDropUp />
        }
      </Button>
    </Grid>
    : <Fab
      color='secondary'
      aria-label='add'
      onClick={() => {setEditingLesson(newLessonTemplate); scrollTo('theoreticalCard')}}
      sx={{
        right: theme => theme.spacing(3),
        bottom: theme => theme.spacing(3),
        position: 'fixed',
      }}
    >
      <Add />
    </Fab>
}

SelectedLessonManager.propTypes = {
  progressBook: PropTypes.object.isRequired,
  lessonsWithItems: PropTypes.array,
  scrollTo: PropTypes.func.isRequired,
  editingLesson: PropTypes.object,
  setEditingLesson: PropTypes.func.isRequired,
}

export default SelectedLessonManager
