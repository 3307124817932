import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Box, Card, CardContent, CardHeader, IconButton, InputAdornment, ListItemIcon, ListItemText, Menu,
  MenuItem, TextField, Typography } from '@mui/material'
import { useSelector } from 'react-redux'
import { AddAPhoto, Check, Close, MoreHoriz } from '@mui/icons-material'
import Avatar from '../_library/Avatar'
import { ACTIVITY_COLLECTION, CHAT_COLLECTION, USER_COLLECTION } from '../../_constants/globals'
import parse from 'html-react-parser'
import { useFirestore } from '../../hooks/useFirestore'
import { useStorage } from '../../hooks/useStorage'
import useAuth from '../../hooks/useAuth'


const DiscussionCard = ({ sx }) => {
  
  const { id: activityId } = useSelector(state => state.data.selectedActivity || {})
  const chatHooks = useFirestore(CHAT_COLLECTION)
  const chats = chatHooks.getDocs()
  const profile = useAuth().getProfile()
  const userHooks = useFirestore(USER_COLLECTION)
  const activityHooks = useFirestore(ACTIVITY_COLLECTION)
  const storage = useStorage('conversations')
  
  const [newMessage, setNewMessage] = useState('')
  const [anchorEl, setAnchorEl] = useState(null)
  const [uploading, setUploading] = useState(false)
  
  const handleSubmit = e => {
    e.preventDefault()
    chatHooks.addDoc({
      activityRef: activityHooks.getDocRef(activityId),
      message: newMessage.replaceAll('\n', '<br>'),
      userRef: userHooks.getDocRef(profile.id),
    })
      .then(() => setNewMessage(''))
  }
  
  const handleDelete = chatId => {
    setAnchorEl(null)
    return chatHooks.deleteDoc(chatId)
  }
  
  const handleUpload = e => {
    setUploading(true)
    storage.uploadFileAction(e.target.files[0])
      .then(url => chatHooks.addDoc({
        activityRef: activityHooks.getDocRef(activityId),
        mediaUrl: url,
        userRef: userHooks.getDocRef(profile.id),
      }))
    setUploading(false)
  }
  
  return (
    <Card id='discussion' sx={sx}>
      <CardHeader title='Discussion' />
      <CardContent>
        {chats?.map((chat, index) => (
          <Box key={chat.id} sx={{ display: 'flex' }}>
            <Avatar user={chat.user} sx={{ mr: 1 }} />
            <Box sx={{
              bgcolor: 'grey.200',
              borderRadius: '20px',
              py: '5px',
              px: 2,
              mb: 1,
              position: 'relative',
              pr: chat.userRef.id === profile.id && index === chats.length - 1 ? '40px' : 2,
            }}>
              <Typography variant='caption'>{chat.user?.firstname} {chat.user?.lastname}</Typography>
              {chat.mediaUrl && <Box
                component='img'
                src={chat.mediaUrl}
                alt='discussion'
                onClick={() => window.open(chat.mediaUrl, '_blank')}
                sx={{ maxWidth: '100%', cursor: 'pointer' }}
              />}
              {chat.message && <Typography>{parse(chat.message)}</Typography>}
              {chat.userRef.id === profile.id && index === chats.length - 1 && !chat.locked &&
                <IconButton onClick={e => setAnchorEl(e.currentTarget)} sx={{ position: 'absolute', right: 0, top: 0 }}><MoreHoriz /></IconButton>
              }
              <Menu
                anchorEl={anchorEl}
                open={!!anchorEl}
                onClose={() => setAnchorEl(null)}
              >
                <MenuItem onClick={() => handleDelete(chat.id)}>
                  <ListItemIcon><Close /></ListItemIcon>
                  <ListItemText>Supprimer</ListItemText>
                </MenuItem>
              </Menu>
            </Box>
          </Box>
        ))}
        <Box component='form' id='newMessageForm' onSubmit={handleSubmit} sx={{ display: 'flex', width: '100%' }}>
          <Avatar user={profile} sx={{ mr: 1 }} />
          <TextField
            value={newMessage}
            onChange={e => setNewMessage(e.target.value)}
            placeholder='Ecrivez un commentaire...'
            fullWidth
            multiline
            margin='dense'
            InputProps={{
              sx: { py: 1, px: 2 },
              endAdornment:
                <InputAdornment position='end'>
                  <IconButton edge='end' component='label' disabled={uploading}>
                    <input hidden accept='image/*' type='file' onInput={handleUpload} />
                    <AddAPhoto />
                  </IconButton>
                </InputAdornment>,
            }}
            sx={{
              bgcolor: 'grey.100',
              m: 0,
              borderRadius: '20px',
              fieldset: { border: 'none' },
            }}
          />
          {newMessage && <IconButton type='submit' sx={{ maxHeight: 40, alignSelf: 'end' }}><Check /></IconButton>}
        </Box>
      </CardContent>
    </Card>
  )
}

DiscussionCard.propTypes = {
  sx: PropTypes.object,
}

export default DiscussionCard
