import React, { useState } from 'react'
import { Box, Button, Card, CardContent, CardHeader, Typography } from '@mui/material'
import { useSelector } from 'react-redux'
import { AirplaneTicket, Engineering, People, Place, Timer } from '@mui/icons-material'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faWalkieTalkie } from '@fortawesome/free-solid-svg-icons'
import { formatDuration, intervalToDuration } from 'date-fns'
import { fr } from 'date-fns/locale'
import parse from 'html-react-parser'


const itemStyle = {
  mb: 1,
  '& svg': {
    width: 40,
  },
}

const DetailsCard = () => {
  
  const { airport, aircrafts, organizer, instructors, pilots, firstDay, lastDay, description } = useSelector(state => state.data.selectedActivity)
  
  const [showFullDescription, setShowFullDescription] = useState(false)
  
  return (
    <Card>
      <CardHeader title='Détails' />
      <CardContent>
        <Box sx={itemStyle}><Engineering sx={{ verticalAlign: 'sub' }} />{organizer
          ? organizer.firstname + ' ' + organizer.lastname
          : <Box component='em' sx={{ color: 'grey.500' }}>Pas de GO</Box>
        }</Box>
        <Box sx={itemStyle}><FontAwesomeIcon icon={faWalkieTalkie} size='lg' />{instructors
          ? instructors.map(u => u.firstname).join(', ')
          : <Box component='em' sx={{ color: 'grey.500' }}>Pas d&apos;instructeur</Box>
        }</Box>
        <Box sx={itemStyle}><People sx={{ verticalAlign: 'sub' }} />{pilots
          ? pilots.map(u => u.firstname).join(', ')
          : <Box component='em' sx={{ color: 'grey.500' }}>Aucun pilote inscrit</Box>
        }</Box>
        <Box sx={itemStyle}><Place sx={{ verticalAlign: 'sub' }} />{airport?.name}</Box>
        <Box sx={itemStyle}><AirplaneTicket sx={{ verticalAlign: 'sub' }} />{aircrafts?.map(a => a.name).join(', ')}</Box>
        <Box sx={itemStyle}><Timer sx={{ verticalAlign: 'sub' }} />Durée: {formatDuration(intervalToDuration({ start: firstDay, end: lastDay }), { locale: fr })}</Box>
        {description && <Box sx={itemStyle}>
          {showFullDescription
            ? <><Typography>{parse(description)}</Typography><Button variant='text' onClick={() => setShowFullDescription(false)}>Voir moins</Button></>
            : <><Typography>{parse(description.slice(0, 140))}...</Typography><Button variant='text' onClick={() => setShowFullDescription(true)}>Voir plus</Button></>
          }
        </Box>}
      </CardContent>
    </Card>
  )
}

export default DetailsCard
