import React, { useEffect, useMemo } from 'react'
import PropTypes from 'prop-types'
import useAuth from '../../hooks/useAuth'
import { Box, Button, Card, CardActions, CardContent, CardHeader, Divider, FormControl, Grid, MenuItem, Select,
  Typography } from '@mui/material'
import { QUALIFICATION_COLLECTION, STATUS_APPLICATION_VALIDATED, USER_COLLECTION } from '../../_constants/globals'
import Loading from '../_library/Loading'
import InlineEditor from '../_library/InlineEditor'
import QualificationCard from '../qualifications/QualificationCard'
import { MEMBERS } from '../../_constants/routes'
import Avatar from '../_library/Avatar'
import { DatePicker } from '@mui/x-date-pickers'
import { useNavigate } from 'react-router'
import { useFirestore } from '../../hooks/useFirestore'


const AccountValidation = ({ userId }) => {
  
  const navigate = useNavigate()
  const profile = useAuth().getProfile()
  const qualificationHooks = useFirestore(QUALIFICATION_COLLECTION)
  const qualifications = qualificationHooks.getDocs()
  const userHooks = useFirestore(USER_COLLECTION, { storeAs: 'subscriptions' })
  const dbSubscriptions = userHooks.getDocs()
  
  useEffect(() => {
    qualificationHooks.listen({ where: [['userRef', '==', userHooks.getDocRef(userId)]] })
    return qualificationHooks.unsubscribe
  }, [userId])
  
  const subscription = useMemo(() => dbSubscriptions?.find(s => s.id === userId), [dbSubscriptions, userId])
  
  const validateAccount = () =>
    userHooks.updateDoc(userId, {
      status: STATUS_APPLICATION_VALIDATED,
      roles: ['pilote'],
      activatedByRef: userHooks.getDocRef(profile.id),
    }).then(() => navigate(MEMBERS))
  
  const updateUserAction = userHooks.updateDoc
  
  if (!subscription) return <Loading />
  else return (
    <Box sx={{ my: 2, mx: 'auto', maxWidth: 900 }}>
      <Typography variant='body2' mb={1} color='textSecondary' gutterBottom>Identité</Typography>
      <Card variant='outlined' sx={{ minWidth: 275, mb: 2 }}>
        <CardContent>
          <Grid item xs={12} mb={1}>
            <InlineEditor title='Nom' value={subscription.lastname} handleSubmit={val => updateUserAction(userId, { lastname: val })} />
          </Grid>
          <Grid item xs={12} mb={1}>
            <InlineEditor title='Prénom' value={subscription.firstname} handleSubmit={val => updateUserAction(userId, { firstname: val })} />
          </Grid>
          <Grid item xs={12} mb={1}>
            <Typography display='inline-block' lineHeight='20px' width={200} gutterBottom sx={{ color: 'rgba(0,0,0,.54)' }}>
              Sexe
            </Typography>
            <FormControl variant='outlined'>
              <Select
                variant='standard'
                id='sex-select'
                value={subscription.gender || 'woman'}
                onChange={e => updateUserAction(userId, { gender: e.target.value })}
                margin='dense'>
                <MenuItem value='woman'>Féminin</MenuItem>
                <MenuItem value='man'>Masculin</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} mb={1}>
            <Typography display='inline-block' lineHeight='20px' width={200} gutterBottom sx={{ color: 'rgba(0,0,0,.54)' }}>
              Date de naissance
            </Typography>
            <DatePicker
              inputFormat='dd-MM-yyyy'
              margin='dense'
              id='birthdate'
              value={subscription.birthdate ? subscription.birthdate : new Date()}
              onChange={val => updateUserAction(userId, { birthdate: val })}
            />
          </Grid>
          <Grid item xs={12} mb={1}>
            <InlineEditor title='Nationalité' value={subscription.nationality} handleSubmit={val => updateUserAction(userId, { nationality: val })} />
          </Grid>
          <Grid item xs={12} mb={1}>
            <Typography display='inline-block' lineHeight='20px' width={200} gutterBottom sx={{ color: 'rgba(0,0,0,.54)' }}>
              Photo
            </Typography>
            <Avatar user={subscription} />
          </Grid>
        </CardContent>
      </Card>
      
      <Typography variant='body2' mb={1} color='textSecondary' gutterBottom>Coordonnées</Typography>
      <Card variant='outlined' sx={{ minWidth: 275, mb: 2 }}>
        <CardContent>
          <Grid item xs={12} mb={1}>
            <InlineEditor title='Email' value={subscription.email} handleSubmit={val => updateUserAction(userId, { email: val })} />
          </Grid>
          <Grid item xs={12} mb={1}>
            <InlineEditor title='Téléphone' value={subscription.phone} handleSubmit={val => updateUserAction(userId, { phone: val })} />
          </Grid>
          <Divider variant='fullWidth' light style={{ margin: '1rem -16px' }} />
          <Typography variant='body1'>Adresse</Typography>
          <Grid item xs={12} mb={1}>
            <InlineEditor title='Rue' value={subscription.address.street} handleSubmit={val => updateUserAction(userId, { address: { street: val } })} />
          </Grid>
          <Grid item xs={12} mb={1}>
            <InlineEditor title='Code postal' value={subscription.address.zipcode} handleSubmit={val => updateUserAction(userId, { address: { zipcode: val } })} />
          </Grid>
          <Grid item xs={12} mb={1}>
            <InlineEditor title='Ville' value={subscription.address.city} handleSubmit={val => updateUserAction(userId, { address: { city: val } })} />
          </Grid>
          <Grid item xs={12} mb={1}>
            <InlineEditor title='Pays' value={subscription.address.country} handleSubmit={val => updateUserAction(userId, { address: { country: val } })} />
          </Grid>
        </CardContent>
      </Card>
      
      <Typography variant='body2' mb={1} color='textSecondary' gutterBottom>Qualifications</Typography>
      {qualifications && qualifications.map(qualification =>
        <QualificationCard qualification={qualification} key={qualification.typeRef.id} />,
      )}
      
      {subscription.onboardingComments && <Card variant='outlined' sx={{ minWidth: 275, mb: 2 }}>
        <CardHeader
          subheader='Commentaire du nouveau membre'
        />
        <CardContent>
          <Typography variant='body2'>{subscription.onboardingComments}</Typography>
        </CardContent>
      </Card>}
      
      <Card variant='outlined' sx={{ minWidth: 275, mb: 2 }}>
        <CardActions sx={{ position: 'relative', p: 4 }}>
          <Typography variant='body1'>Valider la demande d&apos;inscription</Typography>
          <Button
            variant='contained'
            size='large'
            color='primary'
            onClick={validateAccount}
            sx={{ position: 'absolute', right: 30 }}
          >
            Valider
          </Button>
        </CardActions>
      </Card>
    </Box>
  )
}

AccountValidation.propTypes = {
  userId: PropTypes.string.isRequired,
}

export default AccountValidation
