import React, { useEffect } from 'react'
import { Card, CardContent, Typography, Divider, CardHeader, Button, Box } from '@mui/material'
import useAuth from '../../hooks/useAuth'
import { AVAILABILITY_COLLECTION, USER_COLLECTION } from '../../_constants/globals'
import Loading from '../_library/Loading'
import { format, startOfToday } from 'date-fns'
import AvailabilityItem from './AvailabilityItem'
import CTAButton from '../_library/CTAButton'
import { useFirestore } from '../../hooks/useFirestore'


const InstructorPanel = () => {
  
  const profile = useAuth().getProfile()
  const availabilityHooks = useFirestore(AVAILABILITY_COLLECTION)
  const dbAvailabilities = availabilityHooks.getDocs()
  const userHooks = useFirestore(USER_COLLECTION)
  
  useEffect(() => {
    if (profile) {
      availabilityHooks.listen({
        where: [
          ['day', '>=', startOfToday()],
          ['userRef', '==', userHooks.getDocRef(profile.id)],
        ],
        orderBy: [['day', 'asc']],
      })
    }
  }, [profile.id])
  
  const createAvailability = () =>
    availabilityHooks.addDoc({
      day: startOfToday(),
      start: new Date(format(startOfToday(), 'yyyy-MM-dd') + 'T09:00'),
      end: new Date(format(startOfToday(), 'yyyy-MM-dd') + 'T18:00'),
      userRef: userHooks.getDocRef(profile.id),
      user: {
        id: profile.id,
        displayName: profile.firstname + ' ' + profile.lastname,
      },
    })
  
  const handleSync = () => {
    console.log('handleSync')
  }
  
  if (!dbAvailabilities) return <Loading />
  return (
    <Box sx={{ my: 2, mx: 'auto', maxWidth: '900px' }}>
      <Typography variant='body2' color='textSecondary' gutterBottom mb={1}>Disponibilités à venir</Typography>
      <Card variant='outlined' sx={{ minWidth: '275px', mb: 2, bgcolor: 'background.card' }}>
        <CardContent sx={{ px: 0 }}>
          <CardHeader
            action={
              <Button variant='contained' color='primary' onClick={createAvailability}>
                Ajouter une dispo
              </Button>
            }
          />
          {dbAvailabilities.length === 0
            ? <Typography variant='body1' style={{ textAlign: 'center', fontStyle: 'italic' }}>Aucune dispo</Typography>
            : dbAvailabilities.map(availability =>
              <AvailabilityItem item={availability} key={availability.id} />,
            )
          }
        </CardContent>
      </Card>
      <Divider light />
      <CTAButton onClick={handleSync} fullWidth sx={{ mt: 2 }}>Google Calendar Sync</CTAButton>
    </Box>
  )
}

export default InstructorPanel
