import React, { useEffect, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { FLIGHT_COLLECTION, PB_ITEM_COLLECTION, PB_LESSON_COLLECTION, PROGRESS_BOOK_COLLECTION, USER_COLLECTION } from '../../_constants/globals'
import { Box, Button, Checkbox, DialogActions, DialogContent, FormControl, InputLabel, MenuItem, Select, Typography } from '@mui/material'
import CheckPasswordDialog from './CheckPasswordDialog'
import Grader from './Grader'
import { chain, pickBy } from 'lodash'
import { useFirestore } from '../../hooks/useFirestore'


const LessonSetup = ({ openBooks, flight, back, next }) => {
  
  const itemHooks = useFirestore(PB_ITEM_COLLECTION)
  const dbItems = itemHooks.getDocs()
  const lessonHooks = useFirestore(PB_LESSON_COLLECTION, { storeAs: 'lessons' })
  const dbLessons = lessonHooks.getDocs()
  const userHooks = useFirestore(USER_COLLECTION)
  const flightHooks = useFirestore(FLIGHT_COLLECTION)
  const bookHooks = useFirestore(PROGRESS_BOOK_COLLECTION)
  
  const [selectedBook, selectBook] = useState(openBooks[0])
  const [selectedItemIds, setSelectedItemIds] = useState([])
  const [gradedItems, setGradedItems] = useState([])
  const [openPasswordDialog, setOpenPasswordDialog] = useState(false)
  const [verifiedFI, setVerifiedFI] = useState(false)
  const [submitted, setSubmitted] = useState(false)
  
  useEffect(() => {
    if (flight?.pilotRef) {
      lessonHooks.listen({
        where: [['pilotRef', '==', userHooks.getDocRef(flight.pilotRef.id)]],
        orderBy: [['date', 'asc']],
      })
      return lessonHooks.unsubscribe
    }
  }, [flight?.pilotRef])
  
  const bookLessons = useMemo(() => selectedBook && dbLessons?.filter(l => l.progressBookRef.id === selectedBook.id), [selectedBook, dbLessons])
  const itemsWithStatus = useMemo(() => selectedBook && dbItems?.filter(i => i.level === selectedBook.level).map(item => {
    if (bookLessons?.filter(l => l.gradedItems?.some(i => i.id === item.id)).pop()?.gradedItems.find(i => i.id === item.id).grade === 'trainingNeeded')
      return { ...item, status: '●' }
    else if (bookLessons?.some(l => l.gradedItems?.some(i => i.id === item.id && i.grade === item.objective))) return { ...item, status: '✔️' }
    else return { ...item, status: ' ' }
  }), [dbItems, selectedBook, bookLessons])
  const theoreticalItems = useMemo(() => itemsWithStatus && itemsWithStatus.filter(i => i.type === 'theoretical'), [itemsWithStatus])
  const practicalItems = useMemo(() => itemsWithStatus && itemsWithStatus.filter(i => i.type === 'practical'), [itemsWithStatus])
  
  const handleSubmit = () => {
    setSubmitted(true)
    const progressBookIds = gradedItems.length
      ? chain(gradedItems).map('level').uniq().map(level => openBooks.find(b => b.level === level).id).value()
      : chain(selectedItemIds).map(id => dbItems.find(i => i.id === id).level).uniq().map(level => openBooks.find(b => b.level === level).id).value()
    Promise.all(progressBookIds.map(progressBookId => lessonHooks.addDoc(pickBy({
      date: flight.startDate,
      gradedItems: gradedItems.length ? gradedItems : [],
      selectedItemIds: !gradedItems.length && selectedItemIds,
      flightRef: flightHooks.getDocRef(flight.id),
      instructorRef: userHooks.getDocRef(flight.instructorRef.id),
      progressBookRef: bookHooks.getDocRef(progressBookId),
      draft: gradedItems.length === 0,
      withTheory: true,
      withPractice: true,
    }))))
      .then(() => {
        setSelectedItemIds([])
        setGradedItems([])
        return setVerifiedFI(false)
      })
      .catch(err => console.error(err))
      .finally(() => setSubmitted(false))
  }
  
  return <>
    <DialogContent>
      {openBooks.length > 1
        ? <FormControl variant='standard'>
          <InputLabel id='book-select-label'>Livret</InputLabel>
          <Select
            variant='standard'
            labelId='book-select-label'
            id='book-select'
            value={selectedBook?.id || ''}
            onChange={e => selectBook(openBooks.find(book => book.id === e.target.value))}>
            {openBooks.map(book => <MenuItem value={book.id} key={book.id}>{book.name}</MenuItem>)}
          </Select>
        </FormControl>
        : <Typography variant='h5'>{openBooks[0]?.name}</Typography>
      }
      <Typography variant='body1' mt={2}>
        Pour aider {flight.instructor?.firstname} à remplir ton livret, sélectionne les items que vous avez travaillé
      </Typography>
      <Typography variant='h6' mt={2}>Items théoriques</Typography>
      <table style={{ borderCollapse: 'collapse' }}>
        <tbody>
          {theoreticalItems?.map(item =>
            <Box component='tr' key={item.id} sx={selectedItemIds.includes(item.id) && {
              '& td': {
                borderTop: 'solid black 1px',
                borderBottom: 'solid black 1px',
              },
              '& td:first-child': {
                borderLeft: 'solid black 1px',
              },
              '& td:last-child': {
                borderRight: 'solid black 1px',
              },
            }}>
              <td>
                {verifiedFI
                  ? <Grader
                    grade={gradedItems.find(i => i.id === item.id)?.grade}
                    setGrade={val => setGradedItems(prev => [
                      ...prev,
                      {
                        id: item.id,
                        grade: prev.find(i => i.id === item.id)?.grade === val ? null : val,
                        ...dbItems.find(i => i.id === item.id),
                      },
                    ])}
                    objective={item.objective}
                  />
                  : <Checkbox
                    checked={selectedItemIds.includes(item.id)}
                    onChange={e => e.target.checked
                      ? setSelectedItemIds(val => [...val, item.id])
                      : setSelectedItemIds(val => val.filter(id => id !== item.id))
                    }
                    color='primary'
                    sx={{ pl: 0 }}
                  />
                }
              </td>
              <td style={{ textAlign: 'center' }}>{item.status}</td>
              <td>{item.name}</td>
            </Box>,
          )}
        </tbody>
      </table>
      <Typography variant='h6' mt={2}>Items pratiques</Typography>
      <table style={{ borderCollapse: 'collapse' }}>
        <tbody>
          {practicalItems?.map(item =>
            <Box component='tr' key={item.id} sx={selectedItemIds.includes(item.id) && {
              '& td': {
                borderTop: 'solid black 1px',
                borderBottom: 'solid black 1px',
              },
              '& td:first-child': {
                borderLeft: 'solid black 1px',
              },
              '& td:last-child': {
                borderRight: 'solid black 1px',
              },
            }}>
              <td>
                {verifiedFI
                  ? <Grader
                    grade={gradedItems.find(i => i.id === item.id)?.grade}
                    setGrade={val => setGradedItems(prev => [
                      ...prev,
                      {
                        id: item.id,
                        grade: prev.find(i => i.id === item.id)?.grade === val ? null : val,
                        ...dbItems.find(i => i.id === item.id),
                      },
                    ])}
                    objective={item.objective}
                  />
                  : <Checkbox
                    checked={selectedItemIds.includes(item.id)}
                    onChange={e => e.target.checked
                      ? setSelectedItemIds(val => [...val, item.id])
                      : setSelectedItemIds(val => val.filter(id => id !== item.id))
                    }
                    color='primary'
                    sx={{ pl: 0 }}
                  />
                }
              </td>
              <td style={{ textAlign: 'center' }}>{item.status}</td>
              <td>{item.name}</td>
            </Box>,
          )}
        </tbody>
      </table>
      {verifiedFI
        ? <Button variant='contained' color='primary' onClick={handleSubmit} disabled={submitted} sx={{ width: '40%', minWidth: 200, m: '5%' }}>
          Valider
        </Button>
        : <>
          <Button variant='contained' color='primary' onClick={() => setOpenPasswordDialog(true)} disabled={submitted} sx={{ width: '40%', minWidth: 200, m: '5%' }}>
            Remplir en live
          </Button>
          <Button variant='contained' color='primary' onClick={handleSubmit} disabled={submitted} sx={{ width: '40%', minWidth: 200, m: '5%' }}>
            Envoyer le recap à {flight.instructor?.firstname}
          </Button>
        </>
      }
    </DialogContent>
    <DialogActions>
      <Button onClick={back} color='primary'>Retour</Button>
      <Button onClick={next} color='primary'>Fermer</Button>
    </DialogActions>
    {openPasswordDialog && <CheckPasswordDialog instructorRef={flight.instructorRef} onClose={() => setOpenPasswordDialog(false)} setVerifiedFI={setVerifiedFI} />}
  </>
}

LessonSetup.propTypes = {
  openBooks: PropTypes.array.isRequired,
  flight: PropTypes.object.isRequired,
  back: PropTypes.func.isRequired,
  next: PropTypes.func.isRequired,
}

export default LessonSetup
