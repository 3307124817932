import React, { useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import {
  Avatar, Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, ListItemIcon, ListItemText,
  Menu, MenuItem, Typography,
} from '@mui/material'
import { useSelector } from 'react-redux'
import { Close, Edit, Engineering, MoreHoriz } from '@mui/icons-material'
import { ACTIVITY_COLLECTION, ACTIVITY_STATUS_SUBSCRIPTIONS, USER_COLLECTION } from '../../_constants/globals'
import { ACTIVITIES } from '../../_constants/routes'
import { useNavigate } from 'react-router'
import WriteActivityDialog from './WriteActivityDialog'
import SubscribeDialog from './SubscribeDialog'
import { useFirestore } from '../../hooks/useFirestore'
import useAuth from '../../hooks/useAuth'


const ActionBar = ({ activity, size }) => {
  
  const { id, options, status } = activity
  const profile = useAuth().getProfile()
  const adminMode = useSelector(state => state.data.adminMode)
  const navigate = useNavigate()
  const activityHooks = useFirestore(ACTIVITY_COLLECTION)
  const dbActivities = activityHooks.getDocs()
  const userHooks = useFirestore(USER_COLLECTION)
  
  const [anchorEl, setAnchorEl] = useState(null)
  const [organizeModalOpen, setOrganizeModalOpen] = useState(false)
  const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(false)
  const [editOpen, setEditOpen] = useState(false)
  const [openSubscribe, setOpenSubscribe] = useState(false)
  
  const organizerRef = useMemo(() => dbActivities?.find(a => a.id === id).organizerRef, [dbActivities])
  
  const message = useMemo(() => {
    if (status === ACTIVITY_STATUS_SUBSCRIPTIONS && size !== 'small') return 'Clique sur le boutton PARTICIPER pour donner tes dispos'
    else if (size !== 'small') return 'Les inscriptions ne sont pas ouvertes'
  }, [options, size])
  
  const closeMenu = () => setAnchorEl(null)
  
  const handleDelete = () => {
    navigate(ACTIVITIES)
    activityHooks.deleteDoc(id)
  }
  
  const handleOrganize = () =>
    activityHooks.updateDoc(id, { organizerRef: userHooks.getDocRef(profile.id) })
      .then(() => setOrganizeModalOpen(false))
  
  return (
    <Box sx={{ display: 'flex', alignItems: 'center', bgcolor: 'background.default', borderRadius: '10px', p: size === 'small' ? 1 : 2, width: '100%' }}>
      <Avatar src={profile.photoUrl} />
      <Typography component='span' ml={2} flexGrow={1}>{message}</Typography>
      <Box>
        <Button
          variant='contained'
          onClick={() => setOpenSubscribe(true)}
          disabled={status !== ACTIVITY_STATUS_SUBSCRIPTIONS && !profile.roles?.includes('instructeur')}
          sx={{ mr: 1 }}
        >Participer</Button>
        {(adminMode || !organizerRef || organizerRef.id === profile.id) && <Button variant='contained' onClick={e => setAnchorEl(e.currentTarget)} sx={{ bgcolor: 'grey.300', p: 1 }}>
          <MoreHoriz sx={{ color: 'black' }} />
        </Button>}
        <Menu
          anchorEl={anchorEl}
          open={!!anchorEl}
          onClose={closeMenu}
          MenuListProps={{
            'aria-labelledby': 'basic-button',
          }}
        >
          {!organizerRef && <MenuItem onClick={() => {setOrganizeModalOpen(true); closeMenu()}}>
            <ListItemIcon><Engineering /></ListItemIcon>
            <ListItemText>Organiser (GO)</ListItemText>
          </MenuItem>}
          {(adminMode || organizerRef?.id === profile.id) && <MenuItem onClick={() => {setEditOpen(true); closeMenu()}}>
            <ListItemIcon><Edit /></ListItemIcon>
            <ListItemText>Modifier</ListItemText>
          </MenuItem>}
          {adminMode && <MenuItem onClick={() => {setConfirmDeleteOpen(true); closeMenu()}}>
            <ListItemIcon><Close /></ListItemIcon>
            <ListItemText>Supprimer</ListItemText>
          </MenuItem>}
        </Menu>
      </Box>
      {confirmDeleteOpen && <Dialog open onClose={() => setConfirmDeleteOpen(false)}>
        <DialogTitle>Es-tu sûr de vouloir supprimer l&apos;activité ?</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Toutes les inscriptions seront aussi supprimées
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setConfirmDeleteOpen(false)}>Annuler</Button>
          <Button onClick={handleDelete} autoFocus>Confirmer</Button>
        </DialogActions>
      </Dialog>}
      {editOpen && <WriteActivityDialog open onClose={() => setEditOpen(false)} id={id} />}
      {organizeModalOpen && <Dialog open onClose={() => setOrganizeModalOpen(false)}>
        <DialogTitle>Es-tu sûr de vouloir organiser le stage ?</DialogTitle>
        <DialogContent>
          <DialogContentText component='div'>
            Le GO est le référent du stage. Il coordonne l&apos;organisation et doit s&apos;assurer que tout a été fait pour que le stage se passe bien. Nottament:
            <ul>
              <li>Les NOTAMs ont été envoyés</li>
              <li>Les avions ont suffisament de potentiel</li>
              <li>Des instructeurs sont disponibles</li>
              <li>Les pilotes inscrits ont leurs validités à jour</li>
              <li>Les convoyeurs sont identifiés</li>
              <li>Les avions sont hangarés tous les soirs</li>
              <li>Un gite a été réservé</li>
            </ul>
            Pour en savoir plus Télécharge le <a href='https://www.midipyreneesvoltige.net/app/download/12316620822/Guide+pratique+GO+v2.pdf?t=1641845949'>Guide du GO</a>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOrganizeModalOpen(false)}>Annuler</Button>
          <Button onClick={handleOrganize}>Organiser</Button>
        </DialogActions>
      </Dialog>}
      {openSubscribe && <SubscribeDialog activity={activity} onClose={() => setOpenSubscribe(false)} />}
    </Box>
  )
}

ActionBar.propTypes = {
  activity: PropTypes.object,
  size: PropTypes.string,
}

export default ActionBar
