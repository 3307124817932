import React, { useEffect, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { Dialog, DialogTitle, Stepper, Step, StepLabel, Box, useTheme, useMediaQuery, MobileStepper } from '@mui/material'
import CreateFlightForm from './CreateFlightForm'
import { useSelector } from 'react-redux'
import LogbookGuide from './LogbookGuide'
import LessonSetup from '../progressBooks/LessonSetup'
import LessonSummary from '../progressBooks/LessonSummary'
import { onSnapshot } from 'firebase/firestore'
import { FLIGHT_COLLECTION, PROGRESS_BOOK_COLLECTION, USER_COLLECTION } from '../../_constants/globals'
import StatusReport from './StatusReport'
import { useFirestore } from '../../hooks/useFirestore'


export const NewFlightDialog = ({ open, onClose, defaultFlightId }) => {
  
  const theme = useTheme()
  const isUpMd = useMediaQuery(theme.breakpoints.up('md'))
  const adminMode = useSelector(state => state.data.adminMode)
  const bookHooks = useFirestore(PROGRESS_BOOK_COLLECTION, { storeAs: 'openBooks' })
  const openBooks = bookHooks.getDocs()
  const userHooks = useFirestore(USER_COLLECTION)
  const flightHooks = useFirestore(FLIGHT_COLLECTION)
  
  const [activeStep, setActiveStep] = useState(defaultFlightId ? 1 : 0)
  const [flightRef, setFlightRef] = useState(defaultFlightId ? flightHooks.getDocRef(defaultFlightId) : null)
  const [flight, setFlight] = useState(null)
  
  useEffect(() => {
    if (flight?.pilotRef) {
      bookHooks.listen({
        where: [
          ['pilotRef', '==', userHooks.getDocRef(flight.pilotRef.id)],
          ['status', '==', 'open'],
        ],
        orderBy: [['_createdAtTime', 'asc']],
      })
      return bookHooks.unsubscribe
    }
  }, [flight])
  
  useEffect(() => {
    if (flightRef){
      const flightListener = onSnapshot(flightRef, doc => {setFlight({ id: doc.id, ...doc.data() })})
      return () => {
        flightListener()
      }
    }
  }, [flightRef])
  
  const progressBookTab = useMemo(() => openBooks?.length > 0 && !!flight?.instructorRef && !adminMode, [openBooks, flight, adminMode])
  
  const steps = useMemo(() => {
    const commonSteps = ['Création du vol', 'Carnet de route', 'Etat de l\'avion']
    if (progressBookTab) commonSteps.push('Livret de progression')
    return commonSteps
  }, [progressBookTab])
  
  const getStepContent = stepIndex => {
    switch (stepIndex) {
      case 0:
        return <CreateFlightForm next={() => setActiveStep(activeStep + 1)} onClose={onClose} setFlightRef={setFlightRef} />
      case 1:
        return <LogbookGuide flight={flight} next={() => setActiveStep(activeStep + 1)} />
      case 2:
        return <StatusReport flight={flight} next={() => progressBookTab ? setActiveStep(activeStep + 1) : onClose()} />
      case 3:
        return flight.lessonRef
          ? <LessonSummary lessonId={flight.lessonRef.id} next={onClose} back={() => setActiveStep(val => val - 1)} />
          : <LessonSetup openBooks={openBooks} flight={flight} next={onClose} back={() => setActiveStep(val => val - 1)} />
      default:
        return 'Unknown stepIndex'
    }
  }
  
  return (
    <Dialog
      open={open || false}
      onClose={onClose}
      aria-labelledby='form-dialog-title'
      fullScreen={!isUpMd}
      sx={{
        '& .MuiDialog-paperWidthSm': {
          maxWidth: 700,
        },
        '& .MuiInput-underline::before': {
          borderBottomColor: 'rgba(0,0,0,.1)',
        },
      }}
    >
      <DialogTitle id='form-dialog-title'>Nouveau vol</DialogTitle>
      {isUpMd
        ? <Stepper activeStep={activeStep}>
          {steps.map(label => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
        : <MobileStepper
          steps={steps.length}
          activeStep={activeStep}
          backButton={<div />}
          nextButton={<div />}
        />
      }
      <Box p={1} pb={{ xs: '25px', md: 1 }}>
        {getStepContent(activeStep)}
      </Box>
    </Dialog>
  )
}

NewFlightDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  defaultFlightId: PropTypes.string,
}

export default NewFlightDialog
