import React, { useEffect } from 'react'
import SubscriptionTable from './SubscriptionTable'
import AccountValidation from './AccountValidation'
import { parseParams } from '../../_helpers/urlHelper'
import { useLocation } from 'react-router'
import { useFirestore } from '../../hooks/useFirestore'
import { STATUS_APPLICATION_SUBMITTED, USER_COLLECTION } from '../../_constants/globals'


const AccountValidationPanel = () => {
  
  const location = useLocation()
  const userHooks = useFirestore(USER_COLLECTION, { storeAs: 'subscriptions' })
  
  const { userId } = parseParams(location.search)
  
  useEffect(() => {
    userHooks.listen({
      where: [['status', '==', STATUS_APPLICATION_SUBMITTED]],
      orderBy: [['_createdAtTime', 'asc']],
    })
    return userHooks.unsubscribe
  }, [])
  
  return userId
    ? <AccountValidation userId={userId} />
    : <SubscriptionTable />
}

export default AccountValidationPanel
