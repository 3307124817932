/* eslint-disable react/display-name */
import React, { useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { Box, Checkbox, Chip, FormControl, InputLabel, ListItemText, MenuItem, Select, Tooltip, useMediaQuery, useTheme } from '@mui/material'
import MUIDataTable from 'mui-datatables'
import Loading from '../_library/Loading'
import { format } from 'date-fns'
import { expireColor } from '../../_helpers/dateHelper'
import Avatar from '../_library/Avatar'
import EditUserDialog from './EditUserDialog'
import { QUALIFICATION_COLLECTION, ROLE_ADMIN, ROLE_INSTRUCTOR, ROLE_PILOT, STATUS_ACTIVATED, USER_COLLECTION } from '../../_constants/globals'
import { pick } from 'lodash'
import { useFirestore } from '../../hooks/useFirestore'


const ROLES = [ROLE_PILOT, ROLE_INSTRUCTOR, ROLE_ADMIN]
const STORAGE_KEY = 'memberTableState'
const MemberTable = () => {
  
  const theme = useTheme()
  const isUpMd = useMediaQuery(theme.breakpoints.up('md'))
  const userHooks = useFirestore(USER_COLLECTION)
  const dbUsers = userHooks.getDocs()
  const qualificationHooks = useFirestore(QUALIFICATION_COLLECTION)
  const dbQualifications = qualificationHooks.getDocs()
  const adminMode = useSelector(state => state.data.adminMode)
  
  const [selectedUserIndex, selectUserIndex] = useState(null)
  
  useEffect(() => {
    if (adminMode) {
      qualificationHooks.listen({
        where: [['archived', '!=', true]],
        orderBy: [
          ['archived', 'asc'],
          ['type.order', 'asc'],
          ['_createdAtTime', 'desc'],
        ],
      })
      return qualificationHooks.unsubscribe
    }
  }, [adminMode])
  
  const selectedUser = useMemo(() => dbUsers && selectedUserIndex !== null && dbUsers[selectedUserIndex], [dbUsers, selectedUserIndex])
  
  const data = useMemo(() =>
    dbUsers && dbUsers.map(({ photoUrl, firstname, lastname, email, phone, hideCoordinates, balance, roles, id, status, _createdAtTime }) =>
      adminMode
        ? [
          { photoUrl, email }, firstname, lastname, email, phone, balance, roles?.join(', '),
          dbQualifications?.filter(q => q.userRef.id === id),
          dbQualifications?.find(q => q.userRef.id === id && q.type.shortName === 'Cotisation')?.expireDate, // cotisation expire date
          dbQualifications?.find(q => q.userRef.id === id && q.type.shortName === 'SEP')?.expireDate, // SEP expire date
          dbQualifications?.find(q => q.userRef.id === id && q.type.shortName === 'FI')?.expireDate, // FI expire date
          dbQualifications?.find(q => q.userRef.id === id && q.type.shortName === 'Médical')?.expireDate, // Médical expire date
          id,
          status,
          _createdAtTime,
        ]
        : [firstname, lastname, hideCoordinates ? 'caché' : email, hideCoordinates ? 'caché' : phone],
    ), [dbUsers, dbQualifications, adminMode])
  
  const localState = window.localStorage.getItem(STORAGE_KEY) &&
    JSON.parse(window.localStorage.getItem(STORAGE_KEY))
  
  if (!dbUsers) return <Loading />
  else return (
    <Box sx={{ mt: 3, mx: 'auto', maxWidth: '1200px' }}>
      <MUIDataTable
        title='Member List'
        data={data}
        columns={adminMode
          ? [
            { name: '', options: { filter: false, sort: false, searchable: false, customBodyRender: user => <Avatar user={user} /> } },
            {
              name: 'Prénom',
              options: {
                filterList: localState?.filterList[1] || [],
                sortThirdClickReset: true,
              },
            },
            {
              name: 'Nom',
              options: {
                filterList: [],
                sortThirdClickReset: true,
              },
            },
            {
              name: 'Email',
              options: {
                display: 'false',
                filter: false,
              },
            },
            {
              name: 'Téléphone',
              options: {
                display: 'false',
                filter: false,
              },
            },
            {
              name: 'compte',
              options: {
                filter: false,
                customBodyRender: data => <span style={{ color: data < 0 ? 'red' : 'inherit' }}>{data}</span>,
              },
            },
            {
              name: 'roles',
              options: {
                display: 'false',
                filterList: [],
                filterType: 'custom',
                customFilterListOptions: {
                  render: v => v.map(l => l.toUpperCase()),
                  update: (filterList, filterPos, index) => {
                    filterList[index].splice(filterPos, 1)
                    return filterList
                  },
                },
                filterOptions: {
                  logic: (role, filters) => {
                    if (filters.length) return !filters.find(f => role?.includes(f))
                    return false
                  },
                  display: (filterList, onChange, index, column) =>
                    <FormControl variant='standard'>
                      <InputLabel htmlFor='select-multiple-chip'>
                        Roles
                      </InputLabel>
                      <Select
                        variant='standard'
                        multiple
                        value={filterList[index]}
                        renderValue={selected => selected.join(', ')}
                        onChange={event => {
                          filterList[index] = event.target.value
                          onChange(filterList[index], index, column)
                        }}>
                        {ROLES.map(item => (
                          <MenuItem key={item} value={item}>
                            <Checkbox
                              color='primary'
                              checked={filterList[index].indexOf(item) > -1}
                            />
                            <ListItemText primary={item} />
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>,
                },
              },
            },
            {
              name: 'dbQualifications',
              options: {
                filter: false,
                sortCompare: order => (q1, q2) => {
                  const score1 = q1.data.some(q => !q.checkerRef) ? -q1.data?.filter(q => !q.checkerRef).length : q1.data.length
                  const score2 = q2.data.some(q => !q.checkerRef) ? -q2.data?.filter(q => !q.checkerRef).length : q2.data.length
                  return order === 'asc' ? score1 - score2 : score2 - score1
                },
                customBodyRender: data => data?.some(q => !q.checkerRef)
                  ? <Tooltip title={data?.filter(q => !q.checkerRef).map(q => q.type.name).join(', ') || ''}><Chip
                    size='small'
                    variant='outlined'
                    label={data.filter(q => !q.checkerRef).length}
                    sx={{
                      color: 'error.main',
                      borderColor: 'error.main',
                    }}
                  /></Tooltip>
                  : <Tooltip title={data?.map(q => q.type.name).join(', ') || ''}><Chip
                    size='small'
                    variant='outlined'
                    label={data?.length}
                    sx={{
                      color: 'success.main',
                      borderColor: 'success.main',
                    }}
                  /></Tooltip>,
              },
            },
            {
              name: 'cotisation',
              options: {
                display: 'false',
                filter: false,
                customBodyRender: date =>
                  <span style={{ color: expireColor(date) }}>{date ? format(date, 'dd-MM-yyyy') : ''}</span>,
              },
            },
            {
              name: 'SEP',
              options: {
                display: 'false',
                filter: false,
                customBodyRender: date =>
                  <span style={{ color: expireColor(date) }}>{date ? format(date, 'dd-MM-yyyy') : ''}</span>,
              },
            },
            {
              name: 'FI',
              options: {
                display: 'false',
                filter: false,
                customBodyRender: date =>
                  <span style={{ color: expireColor(date) }}>{date ? format(date, 'dd-MM-yyyy') : ''}</span>,
              },
            },
            {
              name: 'Médical',
              options: {
                display: 'false',
                filter: false,
                customBodyRender: date =>
                  <span style={{ color: expireColor(date) }}>{date ? format(date, 'dd-MM-yyyy') : ''}</span>,
              },
            },
            { name: 'id', options: { filter: false, sort: false, display: localState?.columns[12]?.display || 'false' } },
            {
              name: 'status',
              options: {
                filterList: [STATUS_ACTIVATED],
                filterType: 'multiselect',
              },
            },
            {
              name: '_createdAtTime',
              options: {
                display: 'false',
                filter: false,
                customBodyRender: date => date && format(date, 'dd-MM-yyyy'),
              },
            },
          ].map((col, index) => {
            if (localState?.columns[index]?.display) col.options.display = localState?.columns[index].display
            if (localState?.filterList[index]) col.options.filterList = localState?.filterList[index]
            return col
          } )
          : [
            { name: 'Prénom', options: { sortThirdClickReset: true } },
            { name: 'Nom', options: { sortThirdClickReset: true } },
            {
              name: 'Email',
              options: {
                display: isUpMd, customBodyRender: data => data !== 'caché' && <a href={'mailto:' + data}>{data}</a>,
                sortThirdClickReset: true,
              },
            },
            {
              name: 'Téléphone',
              options: {
                customBodyRender: data => data !== 'caché' && <a href={'tel:' + data}>{data}</a>,
                sortThirdClickReset: true,
              },
            },
          ]}
        options={{
          filterType: 'textField',
          selectableRows: 'none',
          responsive: 'standard',
          pagination: adminMode,
          onRowClick: (row, { dataIndex }) => adminMode && selectUserIndex(dataIndex),
          rowHover: false,
          setRowProps: () => ({
            sx: adminMode ? {
              cursor: 'pointer',
              '&:hover': {
                backgroundColor: 'rgba(0, 0, 0, 0.04)',
              },
            } : {},
          }),
          onTableChange: (action, tableState) => {
            if (action === 'propsUpdate') return
            window.localStorage.setItem(STORAGE_KEY, JSON.stringify(pick(tableState, ['columns', 'filterList', 'sortOrder', 'rowsPerPage'])))
          },
          sortOrder: localState?.sortOrder || {},
          rowsPerPage: localState?.rowsPerPage || 10,
          jumpToPage: true,
          rowsPerPageOptions: [10, 50, 100],
        }}
      />
      {selectedUser && <EditUserDialog user={selectedUser} onClose={() => selectUserIndex(null)} />}
    </Box>
  )
}

export default MemberTable
