import React, { useEffect, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { Button, TextField, DialogActions, DialogContent, FormControl, InputLabel, Select, MenuItem, Grid, FormHelperText,
  Typography, ToggleButtonGroup, ToggleButton } from '@mui/material'
import useAuth from '../../hooks/useAuth'
import { addMinutes } from 'date-fns'
import UserSelect from '../_library/UserAutocomplete'
import { DatePicker, TimePicker } from '@mui/x-date-pickers'
import { AIRCRAFT_COLLECTION, BOOKING_COLLECTION, FLIGHT_TYPE_COLLECTION, USER_COLLECTION } from '../../_constants/globals'
import { pickBy } from 'lodash'
import { useFirestore } from '../../hooks/useFirestore'


const EditBookingForm = ({ onClose, booking }) => {
  
  const aircraftHooks = useFirestore(AIRCRAFT_COLLECTION)
  const dbAircrafts = aircraftHooks.getDocs()
  const flightTypeHooks = useFirestore(FLIGHT_TYPE_COLLECTION)
  const dbFlightTypes = flightTypeHooks.getDocs()
  const userHooks = useFirestore(USER_COLLECTION)
  const dbUsers = userHooks.getDocs()
  const profile = useAuth().getProfile()
  const bookingHooks = useFirestore(BOOKING_COLLECTION)

  const [startDate, setStartDate] = useState(booking.startDate)
  const [endDate, setEndDate] = useState(booking.endDate)
  const [comments, setComments] = useState(booking.comments)
  const [aircraftId, setAircraftId] = useState(booking.aircraftRef.id)
  const [flightTypeId, setFlightTypeId] = useState(booking.flightTypeRef.id)
  const [pilot, setPilot] = useState(null)
  const [instructor, setInstructor] = useState(null)
  const [error, setError] = useState({})
  const [submitted, setSubmitted] = useState(false)

  const aircrafts = useMemo(dbAircrafts?.filter(a => a.activated), [dbAircrafts])
  const flightTypes = useMemo(dbFlightTypes?.filter(a => a.activated), [dbFlightTypes])
  const availableInstructors = useMemo(() => dbUsers?.filter(u => u.roles?.includes('instructeur')), [dbUsers])

  useEffect(() => startDate && setEndDate(addMinutes(startDate, 45)), [startDate])

  useEffect(() => {
    if (dbUsers && booking.pilotRef)
      setPilot(dbUsers.find(u => u.id === booking.pilotRef.id))
    if (dbUsers && booking.instructorRef)
      setInstructor(dbUsers.find(u => u.id === booking.instructorRef.id))
  }, [dbUsers, booking])

  const handleSubmit = () => {
    if (!aircraftId)
      setError(prev => ({ ...prev, aircraft: 'missing-aircraft' }))
    if (!flightTypeId)
      setError(prev => ({ ...prev, flightType: 'missing-type' }))
    if (aircraftId && flightTypeId) {
      setSubmitted(true)
      bookingHooks.updateDoc(booking.id, pickBy({
        instructorRef: userHooks.getDocRef(instructor?.id),
        startDate,
        endDate,
        aircraftRef: aircraftHooks.getDocRef(aircraftId),
        pilotRef: userHooks.getDocRef(pilot.id),
        flightTypeRef: flightTypeHooks.getDocRef(flightTypeId),
        comments,
      }))
        .then(onClose)
        .finally(() => setSubmitted(false))
    }
  }

  return <>
    <DialogContent>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography variant='body2'>Début</Typography>
        </Grid>
        <Grid item xs={7}>
          <DatePicker
            disableToolbar
            variant='inline'
            inputFormat='dd-MM-yyyy'
            margin='dense'
            id='startDate-date'
            value={startDate}
            fullWidth
            onChange={setStartDate}
          />
        </Grid>
        <Grid item xs={5}>
          <TimePicker
            variant='inline'
            ampm={false}
            margin='dense'
            id='startDate-time'
            value={startDate}
            onChange={setStartDate}
            minutesStep={5}
          />
        </Grid>
        <Grid item xs={12}>
          <Typography variant='body2'>Fin</Typography>
        </Grid>
        <Grid item xs={7}>
          <DatePicker
            disableToolbar
            variant='inline'
            inputFormat='dd-MM-yyyy'
            margin='dense'
            id='endDate-date'
            value={endDate}
            fullWidth
            onChange={setEndDate}
          />
        </Grid>
        <Grid item xs={5}>
          <TimePicker
            variant='inline'
            ampm={false}
            margin='dense'
            id='endDate-time'
            value={endDate}
            onChange={setEndDate}
            minutesStep={5}
          />
        </Grid>
        <Grid item>
          <Typography variant='body2'>Avion</Typography>
          <ToggleButtonGroup
            size='large'
            value={aircraftId}
            exclusive
            onChange={(event, value) => value && setAircraftId(value)}
            style={{ border: error.aircraft && 'solid red' }}
          >
            {aircrafts && aircrafts.map(aircraft =>
              <ToggleButton key={aircraft.id} value={aircraft.id} disabled={!aircraft.bookable} sx={{
                '&.MuiToggleButton-root.Mui-selected': {
                  backgroundColor: 'primary.light',
                  color: 'black',
                },
              }}>
                {aircraft.name}
              </ToggleButton>,
            )}
          </ToggleButtonGroup>
          {error.aircraft && <FormHelperText>{error.aircraft}</FormHelperText>}
        </Grid>
        <Grid item xs={12} id='flightType'>
          <FormControl
            variant='standard'
            required
            error={!!error.flightType}
            sx={{ width: '100%' }}
          >
            <InputLabel id='type-select-label' shrink>Type de vol</InputLabel>
            <Select
              variant='standard'
              labelId='type-select-label'
              id='type-select'
              value={flightTypeId || ''}
              onChange={e => setFlightTypeId(e.target.value)}>
              {flightTypes?.map(type =>
                <MenuItem key={type.id} value={type.id}>
                  {type.name}
                </MenuItem>,
              )}
            </Select>
            {error.flightType && <FormHelperText color='red'>{error.flightType}</FormHelperText>}
          </FormControl>
        </Grid>
        <Grid container id='crew' item xs={12} spacing={3}>
          <Typography variant='subtitle1' sx={{ width: '100%', mt: 4 }}>Equipage</Typography>
          <Grid item xs={6} >
            {dbUsers && <UserSelect
              users={dbUsers}
              disabled={!profile.roles?.includes('admin') && !profile.roles?.includes('instructeur')}
              user={pilot}
              label='Pilote'
              set={setPilot}
              defaultValue={dbUsers?.find(u => u.id === profile.id)}
              sx={{ width: '100%' }}
            />}
          </Grid>
          <Grid item xs={6}>
            {availableInstructors && <UserSelect
              users={availableInstructors}
              user={instructor}
              label='Instructeur'
              set={setInstructor}
              sx={{ width: '100%' }}
            />}
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <TextField
            variant='standard'
            margin='dense'
            id='comments'
            label='Commentaires'
            fullWidth
            value={comments || ''}
            multiline
            onChange={e => setComments(e.target.value)} />
        </Grid>
      </Grid>
    </DialogContent>
    <DialogActions>
      <Button onClick={onClose} color='primary'>
        Annuler
      </Button>
      <Button onClick={handleSubmit} color='primary' disabled={submitted}>
        Valider
      </Button>
    </DialogActions>
  </>
}

EditBookingForm.propTypes = {
  onClose: PropTypes.func.isRequired,
  booking: PropTypes.object.isRequired,
}

export default EditBookingForm
