import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import { Avatar, AvatarGroup, Box, Card, CardActionArea, CardActions, CardContent, CardMedia, Tooltip, Typography } from '@mui/material'
import { useNavigate } from 'react-router'
import { printEventDuration } from '../../_helpers/dateHelper'
import { EmojiEvents, Female, Flag, Hotel, Lock, Place } from '@mui/icons-material'
import ActionBar from './ActionBar'


const ActivityCard = ({ activity }) => {
  const { airport, users, firstDay, lastDay, id, coverUrl, title, options, organizer } = activity
  
  const navigate = useNavigate()
  
  const printOptionIcons = useCallback(() => options?.map(opt => {
    switch (opt) {
      case 'locked':
        return <Tooltip key='locked' title='Les inscription sont vérouillées'><Lock /></Tooltip>
      case 'accommodation':
        return <Tooltip key='accommodation' title='Un hébergement sur place est prévu'><Hotel /></Tooltip>
      case 'championship':
        return <Tooltip key='championship' title='Il s&apos;agit d&apos;une compétition'><EmojiEvents /></Tooltip>
      case 'nationalTeam':
        return <Tooltip key='nationalTeam' title='Stage réservé aux membres de l&apos;équipe de France'><Flag /></Tooltip>
      case 'girlsOnly':
        return <Tooltip key='girlsOnly' title='Stage réservé aux pilotes féminines'><Female /></Tooltip>
      default:
        return null
    }
  }), [options])
  
  return (
    <Card sx={{ width: { xs: '100%', sm: 330 }, m: 1 }}>
      <CardActionArea onClick={() => navigate('../' + id)}>
        <CardMedia component='img' height='140' image={coverUrl} alt='training' />
        {options?.length > 0 && <Box sx={{ position: 'absolute', top: 105, right: 0, color: 'white', bgcolor: '#555B', p: .5 }}>
          {printOptionIcons()}
        </Box>}
        {organizer && <Tooltip title={`GO: ${organizer.firstname} ${organizer.lastname}`}>
          <Avatar src={organizer.photoUrl} alt='organizer' sx={{ position: 'absolute', top: 108, left: 4, border: 'solid 1px black' }} />
        </Tooltip>}
        <CardContent sx={{ pb: 0 }}>
          <Typography variant='caption' color='secondary'>{printEventDuration(firstDay, lastDay)}</Typography>
          <Typography gutterBottom variant='h5' component='div'>{title}</Typography>
          <Typography variant='body2' color='text.secondary'><Place />{airport?.name + ' ' + airport?.ICAO}</Typography>
          <AvatarGroup max={8} sx={{ mt: 1 }}>
            {users.map(({ photoUrl, firstname }, index) => <Avatar key={index} src={photoUrl} alt={firstname} />)}
          </AvatarGroup>
        </CardContent>
      </CardActionArea>
      <CardActions>
        <ActionBar activity={activity} size='small' />
      </CardActions>
    </Card>
  )
}

ActivityCard.propTypes = {
  activity: PropTypes.object.isRequired,
}

export default ActivityCard
