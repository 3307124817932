import React, { useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from '@mui/material'
import { USER_COLLECTION } from '../../_constants/globals'
import { useFirestore } from '../../hooks/useFirestore'
import useAuth from '../../hooks/useAuth'


const CheckPasswordDialog = ({ onClose, setVerifiedFI, instructorRef }) => {
  
  const userHooks = useFirestore(USER_COLLECTION)
  const auth = useAuth()

  const [password, setPassword] = useState('')
  const [hiddenPassword, setHiddenPassword] = useState('')
  const [error, setError] = useState(null)
  const [submitted, setSubmitted] = useState(false)
  
  const instructor = useMemo(() => userHooks.fetchDoc(instructorRef), [instructorRef])
  
  const onChange = val => {
    setPassword(val.split('').map((char, index) => char === '•' ? password[index] : char).join(''))
    setHiddenPassword(val.replace(/./g, '•'))
  }

  const handleSubmit = e => {
    e.preventDefault()
    setSubmitted(true)
    auth.checkPasswordAction(instructor.email, password)
      .then(res => {
        if (res.data.code === 200) {
          setVerifiedFI(true)
          return onClose()
        } else {
          return setError('Le mot de passe n\'est pas bon')
        }
      })
      .catch(err => {
        console.log(err)
        setError('Le mot de passe n\'est pas bon')
      })
      .finally(() => setSubmitted(false))
  }

  return (
    <Dialog open onClose={onClose} aria-labelledby='form-dialog-title' sx={{
      '& .MuiInput-underline::before': {
        borderBottomColor: 'rgba(0, 0, 0, .1)',
      },
    }}>
      <form onSubmit={handleSubmit}>
        <DialogTitle id='form-dialog-title'>
          Mot de passe de {instructor?.firstname}
        </DialogTitle>
        <DialogContent>
          {instructor?.email && <TextField
            variant='standard'
            type='text'
            value={hiddenPassword}
            onChange={e => onChange(e.target.value)}
            error={!!error}
            helperText={error}
            autoComplete='off'
            disabled={submitted} />}
        </DialogContent>
        <DialogActions>
          <Button type='submit'>Submit</Button>
          <Button onClick={onClose} color='primary'>Fermer</Button>
        </DialogActions>
      </form>
    </Dialog>
  )
}

CheckPasswordDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  instructorRef: PropTypes.object.isRequired,
  setVerifiedFI: PropTypes.func.isRequired,
}

export default CheckPasswordDialog
