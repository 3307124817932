import React, { useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { Alert, Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, MenuItem, Select, Switch, TextField, Typography } from '@mui/material'
import useAuth from '../../hooks/useAuth'
import ImageCropper from '../_library/ImageCropper'
import { ACTIVITY_COLLECTION, AIRCRAFT_COLLECTION, AVAILABILITY_COLLECTION, REQUEST_COLLECTION, USER_COLLECTION } from '../../_constants/globals'
import { head, last, pickBy } from 'lodash'
import { addDays, eachDayOfInterval, format, isSameDay } from 'date-fns'
import { Hotel } from '@mui/icons-material'
import { fr } from 'date-fns/locale'
import { useFirestore } from '../../hooks/useFirestore'
import { useStorage } from '../../hooks/useStorage'


const SubscribeDialog = ({ onClose, activity }) => {
  
  const { id: activityId, title, aircraftRefs, firstDay, lastDay, options } = activity || {}
  const profile = useAuth().getProfile()
  const aircraftHooks = useFirestore(AIRCRAFT_COLLECTION)
  const dbAircrafts = aircraftHooks.getDocs()
  const requestHooks = useFirestore(REQUEST_COLLECTION)
  const dbRequests = requestHooks.getDocs()
  const availabilityHooks = useFirestore(AVAILABILITY_COLLECTION)
  const dbAvailabilities = availabilityHooks.getDocs()
  const storage = useStorage('profile-pictures')
  const userHooks = useFirestore(USER_COLLECTION)
  const activityHooks = useFirestore(ACTIVITY_COLLECTION)
  
  const [photo, setPhoto] = useState(null)
  const [phone, setPhone] = useState(profile.phone)
  const [errors, setErrors] = useState({})
  const [submitted, setSubmitted] = useState(false)
  
  const requests = useMemo(dbRequests?.filter(r => r.userRef?.id === profile.id), [dbRequests, profile.id])
  const availabilities = useMemo(dbAvailabilities?.filter(a => a.userRef?.id === profile.id), [dbAvailabilities, profile.id])
  const aircrafts = useMemo(() => dbAircrafts?.filter(a => aircraftRefs.map(({ id }) => id).includes(a.id)), [dbAircrafts, aircraftRefs])
  const days = useMemo(
    () => firstDay && lastDay && eachDayOfInterval({ start: firstDay, end: lastDay }),
    [firstDay, lastDay],
  )
  
  const handleSubmit = async e => {
    e.preventDefault()
    if (!photo && !profile.photoUrl)
      setErrors(prev => ({ ...prev, photo: 'Une photo est nécessaire pour afficher le planning des vols' }))
    if (!phone)
      setErrors(prev => ({ ...prev, phone: 'Il faut indiquer un numéro de téléphone sur lequel le GO peut te joindre pendant le stage' }))
    if ((photo || profile.photoUrl) && phone) {
      setSubmitted(true)
      try {
        const photoUrl = photo && await storage.uploadFileAction(photo)
        await userHooks.updateDoc(profile.id, pickBy({ photoUrl, phone }))
      }
      finally {setSubmitted(false)}
    }
  }
  
  const handleSetRequest = (aircraftId, day, period, flightType) => {
    const update = requests?.find(r => (!r.aircraftRef || r.aircraftRef?.id === aircraftId) && isSameDay(r.day, day) && r.period === period)
    if (update && flightType) return requestHooks.updateDoc(update.id, { flightType })
    else if (update) return requestHooks.deleteDoc(update.id)
    else return requestHooks.addDoc(pickBy({
      activityRef: activityHooks.getDocRef(activityId),
      aircraftRef: aircraftHooks.getDocRef(aircraftId),
      day, period, flightType,
      userRef: userHooks.getDocRef(profile.id),
    }))
  }
  
  
  const handleSetAvailability = (day, period) => {
    const update = availabilities.find(a => isSameDay(a.day, day) && a.period === period)
    if (update) return availabilityHooks.deleteDoc(update.id)
    else return availabilityHooks.addDoc({
      activityRef: activityHooks.getDocRef(activityId),
      day,
      period,
      start: new Date(format(day, 'yyyy-MM-dd') + (period === 'AM' ? 'T09:00' : 'T14:00')),
      end: new Date(format(day, 'yyyy-MM-dd') + (period === 'AM' ? 'T12:00' : 'T18:00')),
      userRef: userHooks.getDocRef(profile.id),
    })
  }
  
  return (
    <Dialog open onClose={onClose}>
      <DialogTitle>Inscription au stage {title}</DialogTitle>
      <DialogContent>
        {profile.status !== 'Actif' && <>
          <Alert severity='error'>
            Les inscriptions au stage sont bloquées car tu n&apos;es pas membre actif.<br />
            Pour cela il faut remplir les critères suivants:
            <ul>
              <li>Licence, SEP, Médical et FFA à jour et validés par un admin</li>
              <li>Cotisation à jour</li>
              <li>Compte pilote positif</li>
            </ul>
          </Alert>
        </>}
        {(!profile.photoUrl || !profile.phone) && <form onSubmit={handleSubmit}>
          <ImageCropper onComplete={setPhoto} imageUrl={profile.photoUrl} cropShape='round' aspectRatio={1} />
          <Typography>{profile.firstname} {profile.lastname}</Typography>
          <TextField
            label='Téléphone'
            value={phone || ''}
            onChange={e => setPhone((e.target.value))}
            error={!!errors.phone}
            helperText={errors.phone}
            sx={{ my: 1 }}
          />
        </form>}
        {profile.photoUrl && profile.phone && profile.status === 'Actif' && <>
          <Box component='table' sx={{
            borderCollapse: 'collapse',
            '& td': { borderLeft: '1px solid black' },
            width: '100%',
          }}>
            <thead>
              <tr>
                <th colSpan={2} />
                {profile.roles?.includes('instructeur') && <th>FI</th>}
                {aircrafts.map(aircraft => <th key={aircraft.id}>{aircraft.name}</th>)}
                {options.includes('accommodation') && <th><Hotel /></th>}
              </tr>
            </thead>
            <tbody>
              <Box component='tr' sx={{ borderTop: '1px solid black' }}>
                <th colSpan={2}>Convoyage</th>
                {profile.roles?.includes('instructeur') && <td />}
                {aircrafts.map(aircraft => <td key={'inbound'+aircraft.name}>
                  <Select
                    variant='standard'
                    fullWidth
                    value={requests?.find(r => r.aircraftRef?.id === aircraft.id && r.period === 'inbound')?.flightType || ''}
                    onChange={e => handleSetRequest(aircraft.id, head(days), 'inbound', e.target.value)}
                    sx={{ '&:before': { borderBottom: 'none' } }}
                  >
                    <MenuItem value=''><em>vide</em></MenuItem>
                    <MenuItem value='Solo'>Solo</MenuItem>
                    <MenuItem value='Double'>Double</MenuItem>
                  </Select>
                </td>)}
                {options.includes('accommodation') && <td rowSpan={2}>
                  <Switch
                    checked={requests?.some(r => isSameDay(r.day, addDays(head(days), -1)) && r.period === 'accommodation') || false}
                    onChange={() => handleSetRequest(null, addDays(head(days), -1), 'accommodation')}
                  />
                </td>}
              </Box>
              {days.reduce((acc, val) => [...acc, { day: val, period: 'AM' }, { day: val, period: 'PM' }], []).map(({ day, period }) =>
                <Box key={day.getTime()+period} component='tr' sx={{ borderTop: period ==='AM' && '1px solid black' }}>
                  {period ==='AM' && <th rowSpan={2}>{format(day, 'EEEE', { locale: fr })}</th>}
                  <td style={{ border: 'none' }}>{period}</td>
                  {profile.roles?.includes('instructeur') && <td>
                    <Switch
                      checked={availabilities?.some(r => isSameDay(r.day, day) && r.period === period) || false}
                      onChange={() => handleSetAvailability(day, period)}
                    />
                  </td>}
                  {aircrafts.map(aircraft => <td key={day.getTime()+period+aircraft.name}>
                    <Select
                      variant='standard'
                      fullWidth
                      value={requests?.find(r => r.aircraftRef?.id === aircraft.id && isSameDay(r.day, day) && r.period === period)?.flightType || ''}
                      onChange={e => handleSetRequest(aircraft.id, day, period, e.target.value)}
                      sx={{ '&:before': { borderBottom: 'none' } }}
                    >
                      <MenuItem value=''><em>vide</em></MenuItem>
                      <MenuItem value='Solo'>Solo</MenuItem>
                      <MenuItem value='Double'>Double</MenuItem>
                    </Select>
                  </td>)}
                  {options.includes('accommodation') && period === 'PM' && <td rowSpan={2}>
                    <Switch
                      checked={requests?.some(r => isSameDay(r.day, day) && r.period === 'accommodation') || false}
                      onChange={() => handleSetRequest(null, day, 'accommodation')}
                    />
                  </td>}
                </Box>,
              )}
              <Box component='tr' sx={{ borderTop: '1px solid black' }}>
                <th colSpan={2}>Convoyage</th>
                {profile.roles?.includes('instructeur') && <td />}
                {aircrafts.map(aircraft => <td key={'outbound'+aircraft.name}>
                  <Select
                    variant='standard'
                    fullWidth
                    value={requests?.find(r => r.aircraftRef?.id === aircraft.id && r.period === 'outbound')?.flightType || ''}
                    onChange={e => handleSetRequest(aircraft.id, last(days), 'outbound', e.target.value)}
                    sx={{ '&:before': { borderBottom: 'none' } }}
                  >
                    <MenuItem value=''><em>vide</em></MenuItem>
                    <MenuItem value='Solo'>Solo</MenuItem>
                    <MenuItem value='Double'>Double</MenuItem>
                  </Select>
                </td>)}
              </Box>
            </tbody>
          </Box>
        </>}
      </DialogContent>
      <DialogActions>
        {(!profile.photoUrl || !profile.phone) && <Button onClick={handleSubmit} disabled={submitted}>Valider</Button>}
        <Button onClick={onClose} sx={{ color: 'black' }}>Fermer</Button>
      </DialogActions>
    </Dialog>
  )
}

SubscribeDialog.propTypes = {
  onClose: PropTypes.func,
  activity: PropTypes.object,
}

export default SubscribeDialog
