/* eslint-disable react/display-name */
import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'
import MUIDataTable from 'mui-datatables'
import Loading from '../_library/Loading'
import { format, formatDistanceToNow } from 'date-fns'
import { PROGRESS_BOOKS } from '../../_constants/routes'
import CompletenessGraph from './CompletenessGraph'
import { PROGRESS_BOOK_COLLECTION, ROLE_INSTRUCTOR } from '../../_constants/globals'
import { useNavigate } from 'react-router'
import { Box } from '@mui/material'
import { useFirestore } from '../../hooks/useFirestore'
import useAuth from '../../hooks/useAuth'


const ProgressBookTable = () => {
  
  const bookHooks = useFirestore(PROGRESS_BOOK_COLLECTION)
  const dbBooks = bookHooks.getDocs()
  const navigate = useNavigate()
  const adminMode = useSelector(state => state.data.adminMode)
  const profile = useAuth().getProfile()
  
  const progressBooks = useMemo(
    () => dbBooks?.filter(pb => adminMode || profile.roles?.includes(ROLE_INSTRUCTOR) || pb.pilotRef.id === profile.id),
    [dbBooks, adminMode, profile.id],
  )
  
  const data = useMemo(() =>
    progressBooks?.map(({ id, pilot, level, status, _createdAtTime, _updatedAt, completeness }) =>
      [id, pilot, level, status, _createdAtTime, _updatedAt, completeness],
    ), [progressBooks])
  
  if (!progressBooks) return <Loading />
  else return (
    <Box sx={{ my: 2, mx: 'auto', maxWidth: '1200px' }}>
      <MUIDataTable
        data={data}
        columns={[
          { name: 'id', options: { filter: false, sort: false, display: 'false' }  },
          { name: 'pilot', options: { customBodyRender: val => val && val.firstname + ' ' + val.lastname } },
          { name: 'level', label: 'Type' },
          {
            name: 'status',
            label: 'Statut',
            options: {
              filterList: ['open'],
              filterType: 'multiselect',
            },
          },
          {
            name: '_createdAtTime',
            label: 'Ouverture du livret',
            options: {
              display: 'false',
              customBodyRender: val => val &&
                formatDistanceToNow(val, { addSuffix: true }) + ' (' + format(val, 'dd-MM-yyyy') + ')',
            },
          },
          {
            name: 'updatedAt',
            label: 'Dernière mise à jour',
            options: {
              customBodyRender: val => val &&
                formatDistanceToNow(val, { addSuffix: true }) + ' (' + format(val, 'dd-MM-yyyy') + ')',
            },
          },
          {
            name: 'completeness',
            label: 'Completeness',
            options: {
              customBodyRender: val => <CompletenessGraph completeness={val} />,
              sortCompare: order => (a, b) => (b.data.validatedItems / b.data.total - a.data.validatedItems / a.data.total) * (order === 'asc' ? 1 : -1),
            },
          },
        ]}
        options={{
          filterType: 'textField',
          selectableRows: 'none',
          responsive: 'simple',
          pagination: false,
          onRowClick: row => navigate(`${PROGRESS_BOOKS}/${row[0]}`),
          setRowProps: () => ({ sx: { cursor: 'pointer' } }),
          textLabels: { body: { noMatch: 'Pas de livret' } },
        }}
      />
    </Box>
  )
}

export default ProgressBookTable
