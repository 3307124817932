import React, { useEffect, useMemo, useState } from 'react'
import { CircularProgress, Grid, TextField, Autocomplete, ToggleButton, ToggleButtonGroup, Box } from '@mui/material'
import { ACTIVITY_COLLECTION, AIRCRAFT_COLLECTION, AIRPORT_COLLECTION, USER_COLLECTION } from '../../_constants/globals'
import { omit, pickBy } from 'lodash'
import { DatePicker } from '@mui/x-date-pickers'
import Avatar from '../_library/Avatar'
import PropTypes from 'prop-types'
import { EmojiEvents, Female, Flag, Hotel } from '@mui/icons-material'
import ImageCropper from '../_library/ImageCropper'
import { useFirestore } from '../../hooks/useFirestore'
import { useStorage } from '../../hooks/useStorage'


const WriteActivityForm = ({ formRef, setSubmitted, onComplete, activity }) => {
  
  const aircraftHooks = useFirestore(AIRCRAFT_COLLECTION)
  const dbAircrafts = aircraftHooks.getDocs()
  const airportHooks = useFirestore(AIRPORT_COLLECTION)
  const dbAirports = airportHooks.getDocs()
  const userHooks = useFirestore(USER_COLLECTION)
  const dbUsers = userHooks.getDocs()
  const activityHooks = useFirestore(ACTIVITY_COLLECTION)
  const storage = useStorage('activities')
  
  const [cover, setCover] = useState(null)
  const [title, setTitle] = useState(activity?.title)
  const [airport, setAirport] = useState(null)
  const [aircrafts, setAircrafts] = useState([])
  const [firstDay, setFirstDay] = useState(activity?.firstDay)
  const [lastDay, setLastDay] = useState(activity?.lastDay)
  const [organizer, setOrganizer] = useState(null)
  const [options, setOptions] = useState(activity?.options || [])
  const [description, setDescription] = useState(activity?.description?.replaceAll('<br>', '\n'))
  const [errors, setErrors] = useState({})
  
  useEffect(() => {
    airportHooks.listen({
      orderBy: [['occurrences', 'desc']],
    })
    return airportHooks.unsubscribe
  }, [])
  
  const allAircrafts = useMemo(dbAircrafts?.filter(a => a.activated), [dbAircrafts])
  const allUsers = useMemo(dbUsers?.sort((a, b) => {
    const aScore = a.roles?.includes('instructeur') && a.status === 'Actif' ? 2 : a.status === 'Actif' ? 1 : 0
    const bScore = b.roles?.includes('instructeur') && b.status === 'Actif' ? 2 : b.status === 'Actif' ? 1 : 0
    return bScore - aScore
  }), [dbUsers])
  
  useEffect(() => {
    if (activity) {
      setAirport(dbAirports?.find(a => a.id === activity.airportRef.id) || null)
      setAircrafts(activity.aircraftRefs?.map(ref => ref.id))
      setOrganizer(allUsers?.find(u => activity.organizerRef?.id === u.id) || null)
    }
  }, [activity, dbAirports, allUsers, allAircrafts])
  
  const handleSubmit = async e => {
    e.preventDefault()
    if (!airport)
      setErrors(prev => ({ ...prev, airport: 'il faut sélectionner un terrain' }))
    if (!aircrafts.length)
      setErrors(prev => ({ ...prev, aircrafts: 'il faut sélectionner un avion' }))
    if (!firstDay)
      setErrors(prev => ({ ...prev, firstDay: 'il faut sélectionner une date' }))
    if (!lastDay)
      setErrors(prev => ({ ...prev, lastDay: 'il faut sélectionner une date' }))
    if (lastDay.getTime() < firstDay.getTime())
      setErrors(prev => ({ ...prev, lastDay: 'Le dernier jour doit etre ulterieur au premier' }))
    if (aircrafts && airport && firstDay && lastDay && lastDay.getTime() >= firstDay.getTime()) {
      setSubmitted(true)
      try {
        const coverUrl = cover && await storage.uploadFileAction(cover)
        await activityHooks.setDoc(activity?.id, pickBy({
          coverUrl,
          title,
          airportRef: airportHooks.getDocRef(airport.id),
          aircraftRefs: aircrafts.map(a => aircraftHooks.getDocRef(a.id)),
          firstDay,
          lastDay,
          organizerRef: userHooks.getDocRef(organizer?.id),
          description: description?.replaceAll('\n', '<br>'),
          options,
        }))
        onComplete()
      }
      finally {setSubmitted(false)}
    }
  }
  
  if (!allAircrafts || dbAirports) return (<CircularProgress color='inherit' />)
  else return <form onSubmit={handleSubmit} ref={formRef}>
    <ImageCropper onComplete={setCover} imageUrl={activity?.coverUrl} />
    <Box sx={{ px: 1 }}>
      <TextField
        variant='outlined'
        margin='dense'
        label='Nom du stage ou de la compétition'
        fullWidth
        value={title || ''}
        onChange={e => setTitle(e.target.value)}
        sx={{ mt: 2 }}
      />
      <Autocomplete
        autoSelect
        id='airport-select'
        options={dbAirports}
        getOptionLabel={option => option.ICAO ? option.ICAO + ' - ' + option.name : ''}
        renderInput={params =>
          <TextField
            variant='outlined'
            {...params}
            label='Terrain'
            fullWidth
            error={Boolean(errors.airport)}
            helperText={errors.airport}
            ref={el => {
              if (errors.airport && el) scroll.current = el
            }}
            sx={{ mt: 2 }}
          />
        }
        value={airport}
        onChange={(_, value) => {
          setErrors(prev => omit(prev, 'airport'))
          setAirport(value)
        }}
        isOptionEqualToValue={(option, value) => value && option.id === value.id}
        disableClearable
      />
      <ToggleButtonGroup
        fullWidth
        value={aircrafts}
        color='primary'
        onChange={(_, value) => {
          setErrors(prev => omit(prev, 'aircrafts'))
          setAircrafts(value)
        }}
        sx={{ mt: 2 }}
      >
        {allAircrafts.map(aircraft =>
          <ToggleButton
            key={aircraft.id}
            value={aircraft.id}
            sx={{
              color: errors.aircrafts ? 'error.main' : 'primary.main',
              '&.Mui-selected': {
                color: 'white',
                bgcolor: 'primary.main',
                '&:hover': {
                  color: 'white',
                  bgcolor: 'primary.main',
                },
              },
              fontSize: errors.aircrafts ? '0.75rem' : 'inherit',
              lineHeight: errors.aircrafts ? 1.66 : 'inherit',
            }}
          >
            {aircraft.name}
          </ToggleButton>,
        )}
      </ToggleButtonGroup>
      {errors.aircrafts && <Box sx={{ color: 'error.main', fontSize: '0.75rem', lineHeight: 1.66 }}>{errors.aircrafts}</Box>}
      <Grid container id='dates' spacing={1} mt={1}>
        <Grid item xs={6}>
          <DatePicker
            variant='inline'
            margin='dense'
            id='firstDay-date'
            label='Premier jour'
            value={firstDay || null}
            onChange={value => {
              setErrors(prev => omit(prev, 'firstDay'))
              setFirstDay(value)
            }}
            error={Boolean(errors.firstDay)}
          />
          {errors.firstDay && <Box sx={{ color: 'error.main', fontSize: '0.75rem', lineHeight: 1.66 }}>{errors.firstDay}</Box>}
        </Grid>
        <Grid item xs={6}>
          <DatePicker
            variant='inline'
            margin='dense'
            id='lastDay-date'
            label='Dernier jour'
            value={lastDay || null}
            onChange={value => {
              setErrors(prev => omit(prev, 'lastDay'))
              setLastDay(value)
            }}
            error={Boolean(errors.lastDay)}
          />
          {errors.lastDay && <Box sx={{ color: 'error.main', fontSize: '0.75rem', lineHeight: 1.66 }}>{errors.lastDay}</Box>}
        </Grid>
      </Grid>
      <Autocomplete
        id='organizerSelect'
        options={allUsers}
        isOptionEqualToValue={(option, value) => option?.id === value?.id}
        groupBy={u => {
          if (u.roles?.includes('instructeur') && u.status === 'Actif') return 'Instructeurs'
          else if (u.status === 'Actif') return 'Actifs'
          else return 'Anciens'
        }}
        value={organizer}
        onChange={(_, value) => setOrganizer(value)}
        getOptionLabel={u => u?.firstname + ' ' + u?.lastname}
        renderOption={(props, option) => <li {...props} key={'organizer-' + option.id}>
          <Avatar user={option} sx={{ mr: 1 }} />
          {option.firstname + ' ' + option.lastname}
        </li>}
        renderInput={params => (
          <TextField
            {...params}
            variant='outlined'
            label='Organisateur (GO)'
            sx={{ mt: 2 }}
          />
        )}
        loading={!allUsers}
      />
      <TextField
        variant='outlined'
        margin='dense'
        label='Quels sont les détails ?'
        fullWidth
        value={description || ''}
        multiline
        onChange={e => {
          setDescription(e.target.value)
        }}
        rows={3}
        sx={{ mt: 2 }}
      />
      <ToggleButtonGroup
        value={options}
        onChange={(_, val) => setOptions(val)}
        fullWidth
        sx={{ mt: 2 }}
      >
        <ToggleButton value='accommodation' title='Besoin de logement'>
          <Hotel />
        </ToggleButton>
        <ToggleButton value='championship' title='Compétition'>
          <EmojiEvents />
        </ToggleButton>
        <ToggleButton value='nationalTeam' title='Stage équipe de France'>
          <Flag />
        </ToggleButton>
        <ToggleButton value='girlsOnly' title='Stage fille'>
          <Female />
        </ToggleButton>
      </ToggleButtonGroup>
    </Box>
  </form>
}

WriteActivityForm.propTypes = {
  formRef: PropTypes.object,
  setSubmitted: PropTypes.func,
  onComplete: PropTypes.func,
  activity: PropTypes.object,
}

export default WriteActivityForm
