import React, { useEffect, useMemo } from 'react'
import PropTypes from 'prop-types'
import { PB_ITEM_COLLECTION, PB_LESSON_COLLECTION, PB_TEMPLATE_COLLECTION } from '../../_constants/globals'
import { Button, DialogActions, DialogContent, Typography } from '@mui/material'
import Loading from '../_library/Loading'
import { Link } from 'react-router-dom'
import { PROGRESS_BOOKS } from '../../_constants/routes'
import { chain, groupBy } from 'lodash'
import { useFirestore } from '../../hooks/useFirestore'


const LessonSummary = ({ back, next, lessonId }) => {
  
  const templateHooks = useFirestore(PB_TEMPLATE_COLLECTION, { storeAs: 'templates' })
  const dbTemplates = templateHooks.getDocs()
  const itemHooks = useFirestore(PB_ITEM_COLLECTION)
  const dbItems = itemHooks.getDocs()
  const lessonHooks = useFirestore(PB_LESSON_COLLECTION)
  
  useEffect(() => {
    templateHooks.listen({
      orderBy: [['order', 'asc']],
    })
    return templateHooks.unsubscribe
  })
  
  const lesson = useMemo(() => lessonHooks.fetchDoc(lessonHooks.getDocRef(lessonId)), [lessonId])
  
  const groupedItems = useMemo(
    () => lesson && dbTemplates && dbItems &&
      chain(lesson.gradedItems?.length ? lesson.gradedItems : lesson.selectedItemIds.map(id => dbItems.find(i => i.id === id)))
        .groupBy('level')
        .mapKeys((_, level) => dbTemplates.find(t => t.level === level).name)
        .mapValues(group => groupBy(group, 'type'))
        .toPairs()
        .value(),
    [lesson, dbTemplates, dbItems],
  )
  
  return <>
    <DialogContent>
      <Typography variant='h4'>Résumé</Typography>
      {groupedItems
        ? groupedItems.map(pair => <div key={pair[0]}>
          <Typography variant='h5'>Livret: <Link to={PROGRESS_BOOKS + '/open'}>{pair[0]}</Link></Typography>
          {pair[1].theoretical && <>
            <Typography variant='h6' mt={2}>Items théoriques</Typography>
            <ul>{pair[1].theoretical.map(item => <li key={item.id}>{item.name}</li>)}</ul>
          </>}
          {pair[1].practical && <>
            <Typography variant='h6' mt={2}>Items pratiques</Typography>
            <ul>{pair[1].practical.map(item => <li key={item.id}>{item.name}</li>)}</ul>
          </>}
        </div>)
        : <Loading />
      }
    </DialogContent>
    <DialogActions>
      <Button onClick={back} color='primary'>
        Retour
      </Button>
      <Button onClick={next} color='primary'>
        Fermer
      </Button>
    </DialogActions>
  </>
}

LessonSummary.propTypes = {
  lessonId: PropTypes.string.isRequired,
  back: PropTypes.func.isRequired,
  next: PropTypes.func.isRequired,
}

export default LessonSummary
