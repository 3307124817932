import React from 'react'
import PropTypes from 'prop-types'
import { Navigate, Route, Routes } from 'react-router-dom'
import Header from '../menu/Header'
import { useNavigate } from 'react-router'
import Page from './Page'
import { compact } from 'lodash'


const TabbedPage = ({ tabs, containerSx }) => {
  
  const navigate = useNavigate()
  
  return (
    <Page withTopBarAndMenu containerSx={containerSx}>
      <Routes>
        {compact(tabs).map(tab =>
          <Route
            key={'route' + tab.key}
            path={tab.route}
            element={<>
              <Header
                setValue={navigate}
                value={tab.key}
                title={tab.title || tab.label}
                tabs={compact(tabs)}
              >
                {tab.headerContent || <></>}
              </Header>
              {tab.content}
            </>}
          />,
        )}
        <Route path='*' element={<Navigate replace to={tabs[0].route} />} />
      </Routes>
    </Page>
  )
}

TabbedPage.propTypes = {
  tabs: PropTypes.array.isRequired,
  containerSx: PropTypes.object,
}

export default TabbedPage
